import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import Mainheader from '../Common/Mainheader'
import { Helmet } from 'react-helmet'

export const MechatronicsSystems = () => {
    const wiring = [
        {
            id: 1, imgurl: './images/products/AirbagDeactivationSwitch.jpg', prodName: 'Airbag Deactivation Switch' },
        {
            id: 2, imgurl: './images/products/AutomaticGearSelectorDNASwitch.jpg', prodName: 'DNA Drive Mode / Suspension Level Selector' },
        {
            id: 3, imgurl: './images/products/BMWCentralStackConsoleSwitchBar.jpg', prodName: 'Central Stack Console Switch' },
        {
            id: 4, imgurl: './images/products/DoorCentralLockingSwitch.jpg', prodName: 'Central Door Locking, Hazard and Traction Control Switch' },
        { id: 5, imgurl: './images/products/EngineStartStop Switch.jpg', prodName: 'Engine Start / Stop, Switch' },
        { id: 6, imgurl: './images/ProductPhotos/HeadlightLevellingSwitch.jpg', prodName: 'Headlight Levelling Switch' },
        { id: 7, imgurl: './images/products/HeadlightSwitch.jpg', prodName: 'Light Control Unit' },
        { id: 8, imgurl: './images/products/InteriorDoorLightSensorSwitch.jpg', prodName: 'Door and Trunk Closure Switch  ' },
        {id:10,imgurl:'./images/products/PedalBrakeSwitch.jpg',prodName:'Clutch, Pedal and Brake Switch'},
        {id:11,imgurl:'./images/products/WheelSpeedSensor.jpg',prodName:'Sealed Engine Hood '},
        {id:12,imgurl:'./images/products/WindowsSwitch.jpg',prodName:'Windows Switch'},
        {id:13,imgurl:'./images/products/SteeringWheelSwitchPanel.jpg',prodName:'Steering Wheel Switch'},
        { id: 14, imgurl:'./images/ProductPhotos/ininternistato6.jpg',prodName:'Fuel Filler and Electrical Recharging Plug Flap Switch'},
        { id: 15, imgurl:'./images/products/HazardWarningSwitch.jpg',prodName:'Hazard and Central Locking Switch'},
        { id: 16, imgurl: './images/products/MultimediaPlugs.jpg', prodName:'Multimedia Plugs'},
    ]
  return (
    <>
        <Helmet>
              <title>Mechatronics Products | Viney Corporation Limited</title>
            <meta name='description' content='Our Mechatronics Products products like switches, modules etc designed to enhance automotive performance & reliability. Contact us for innovative solutions.' />
        </Helmet>
        <Jumbohead headertitle="Mechatronics Products" Pagename=" Mechatronics Products" bgurl='url("./images/page-header3.webp")' />

        <section className='connectionsystems'>
            <div className="container">
                <div className="heading text-center" data-aos="fade-down" data-aos-duration="1500" data-aos-once='true'>
                        <p>we have a product range of over 500 Mechatronic Products. Our key Mechatronics Products are hidden
and visible switches. As of March 31, 2024, we are amongst one of the few Indian switch manufacturers with in-house
capabilities to design and manufacture switches catering to both high-end and mass-market vehicles (Source: CRISIL Report).</p>
                    <img src="./images/carimg.png" className='car_parts mx-auto' alt="" />
                </div>
            </div>
        </section>  

        <section className='light-bg'>
            <div className="container">
                <div className="heading text-center mb-5">
                    <Mainheader heading='Switches' />
                    <p>switches are integral to the proper function of any vehicle and come in a variety of shapes and sizes each with its
own specific function. Some of the most common automotive switches are the starter switch, ignition switch, headlight switch,
turn signal switch, and wiper switch. All these switches are important, as they provide protection against overload, overheating,
and short circuits.</p>
                </div>
                    <div className="row" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                        {wiring.map((item)=>
                            <div className="col-md-3 col-6 mb-4" key={item.id}>
                                <div className="card p-3 pb-0 box_shadow">
                                    <div className="custom_img">
                                        <img src={item.imgurl} alt={item.prodName} />
                                    </div>
                                    <h6 className='text-center text-secondary my-3'>{item.prodName}</h6>
                                </div>
                            </div>
                        )}
                    </div>
            </div>
        </section>
    </>
  )
}
