import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export const Product = () => {
  return (
    <>
        <Helmet>
              <title>Products | Viney Corporation Limited</title>
            <meta name='description' content='We provide a wide range of connecting & Mechatronics Products products like wiring harnesses, control units, etc. ensuring seamless integration & functionality.' />
        </Helmet>
      <Jumbohead headertitle="Products" Pagename=" Products" bgurl='url("./images/page-header2.webp")' />

      <section>
        <div className="container">
          <div className="heading" data-aos="fade-down" data-aos-duration="1500" data-aos-once='true'>
            <h3>Overview</h3>
          </div>
          <div className="row mt-2">
            <div className="col-md-12 order-2 order-md-1" data-aos="fade-right" data-aos-duration="1500" data-aos-once='true'>
                          <p>We are one of the leading manufacturer, supplier and exporter of automotive components i.e., Mechatronic Products and Connective Products. We are a Tier-1 supplier for OEMs for both Mechatronic Products and Connective Products, and a Tier-2 supplier and Tier-3 supplier for OEMs for Connective Products. As of March 31, 2024, one out of every six two-wheelers sold in India are fitted with one of our device wiring harness. As of March 31, 2024, we are amongst one of the few Indian switch manufacturers with in-house capabilities to design and manufacture switches catering to both high-end and mass-market vehicles (Source: CRISIL Report).</p>
              <p>We have a diverse product range of products. As of March 31, 2024, we had a product range of over 500 Mechatronic Products
and over 2,500 Connective Products. Our focus is on developing switches, wiring harness, terminals, connectors and other
components that align with the changing preferences and requirements of customers. We have developed extensive in-house
capabilities for embedded systems and application software. Our product suite offers our customers comprehensive solutions,
addressing mechanical, electrical, and software aspects (Source: CRISIL Report). </p>
            </div>
            {/* <div className="col-md-6 order-1 order-md-2 mb-3 mb-md-0" data-aos="fade-left" data-aos-duration="1500" data-aos-once='true'>
              <div className="custom_img">
                <img src="./images/product_overview.webp" alt="Viney Corpoartion" />
              </div>
            </div> */}
          </div>
        </div>
      </section>

      {/* <section className='BoardofDirectors overflow-hidden position-relative'>
        <div className="row">
          <div className="col-md-4 mb-3 mb-md-0" data-aos="fade-right" data-aos-duration="1500" data-aos-once='true'>
            <div className='custom_img'>
              <img src="./images/EV_Agnostic.webp" alt="" />
            </div>
          </div>
          <div className="col-md-8 pe-0" >
            <div className="containerdirector" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
              <div className="heading">
                <h3 className='text-blue'>EV Agnostic</h3>
              </div>
              <p>Our business strategy is firmly rooted in being vehicle platform agnostic, positioning us adeptly to cater to the diverse needs of both ICE and EV vehicle platforms. Currently, our operations extend to supplying crucial components for both ICE and EV platforms, spanning our mechatronics and connective systems products.</p>
              <p>We are witnessing a significant increase in the complexity of products supplied to both ICE and EV vehicle platforms in Mechatronics Products. This complexity is expected to translate into a higher value per vehicle, further emphasizing our commitment to delivering innovative solutions that align with the evolving demands of the automotive industry.</p>
              <p>Our ability to seamlessly transition between traditional and electric vehicle technologies underscores our resilience and adaptability. Our business remains dynamic and forward-looking in the transformative period of EVs. We are poised not only to meet the challenges presented by this transformative era but also to thrive and expand our operations as EV penetration continues to reshape the automotive landscape.</p>
            </div>
          </div>
        </div>
      </section> */}

      <section className='light-bg mt-5 mt-md-0 systems'>
        <div className="container">
          <div className="heading text-center">
            <h3>Our Mechatronic Products and Connective Products</h3>
            <p>Our Mechatronic Products and Connective Products are supplied for both internal combustion engine (“ICE”) vehicles and
electric vehicles (“EVs”). Further, we are able to transition the products initially manufactured by us for luxury vehicle market
to cater to mass-market vehicles. Our ability to cater to both EVs and ICE automotive OEMs insulate us to an extent against
transition in industry trends towards any particular sector and positions us to leverage growth in any category. Hence, we are a
                          segment agnostic technology driven company (Source: CRISIL Report).</p>
          </div>
          <div className="row mt-5">
            <div className="col-md-6 mb-4 mb-md-0" data-aos="fade-right" data-aos-duration="1500" data-aos-once='true'>
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <div className="custom_img">
                    {/* <img src="./images/connection_system.webp" alt="" /> */}
                    <img src="./images/products/rubberseal_s.jpg" alt="" />
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h5 className="mt-0">Connective Products</h5>
                  <p>We design and manufacture a wide variety of connective systems components such as fuse boxes, wiring harnesses, terminals, bulbs, starter relay, front brake switch, relay winker, cap noise, cap filter and connectors.</p>
                  <Link to="/connective-products" className='theme-btn'>Read More...</Link>
                </div>
              </div>

            </div>
            <div className="col-md-6 mb-4 mb-md-0" data-aos="fade-left" data-aos-duration="1500" data-aos-once='true'>
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <div className="custom_img">
                    <img src="./images/products/AutomaticGearSelectorDNASwitch.jpg" alt="" />
                    {/* <img src="./images/mechatronic_system.webp" alt="" /> */}
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h5 className="mt-0">Mechatronics Products</h5>
                  <p>We also manufacture diverse mechatronic systems components such as visible switches and hidden switches, and other products such as steering wheel controls gear paddles, light control units, pedal switches and sensors, multimedia plugs and airbag switches.</p>
                  <Link to="/mechatronics-products" className='theme-btn'>Read More...</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
