import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import { Helmet } from 'react-helmet'

export const Csr = () => {
  return (
    <>
        <Helmet>
                <title>Corporate Social Commitment (CSR) | Viney Corporation</title>
                <meta name='description' content='Learn about our commitment to social welfare and community development through our CSR initiatives, fostering positive impact and community engagement.' />
        </Helmet>
        <Jumbohead headertitle="Social Commitment (CSR)" Pagename=" Corporate Social Responsibilty" bgurl='url("./images/page-header2.webp")'/>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12" data-aos="fade-right" data-aos-duration="1500" data-aos-once='true'>
                  <div className="heading">
                        <h4 className="heading_span fw-medium">
                          We Care
                        </h4>
                        <h3>Corporate Social Responsibility</h3>
                  </div>
                  <p>Our CSR programs are committed to the educational welfare and
development of society, and to do that, we have contributed to promoting education, including special education and
employment enhancing vocational skills especially among children, women, elderly and the differently abled and livelihood
enhancement projects.
</p>
              </div>
            </div>
          </div>
        </section>

        <section className='BoardofDirectors overflow-hidden position-relative'>
             <div className="row">
                <div className="col-md-4 mb-3 mb-md-0" data-aos="fade-right" data-aos-duration="1500" data-aos-once='true'>
                  <div className='custom_img'>
                          <img src="./images/csr.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-8 pe-0" >
                   <div className="containerdirector" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                            {/* <h6>Our CSR programs are committed to the overall welfare and development of society, and to do that, we have identified the below-mentioned key areas of focus:</h6> */}
                            <ul className='ps-4 mt-3 achiev_list'>
                            <li className='position-relative mb-3'>
                                <p>Promoting gender equality and empowering women means setting up hostels and homes for women and orphans. setting up old age homes, daycare centers, and other facilities for senior citizens and undertaking measures to reduce the inequalities faced by socially and economically backward groups.</p>
                            </li>
                            <li className='position-relative mb-3'>
                                <p>Promoting healthcare, including preventive healthcare and sanitation, and making available safe drinking water.</p>
                            </li>
                            <li className='position-relative mb-3'>
                                <p>Ensuring environmental sustainability, ecological sustainability, protection of flora and fauna, animal welfare, agro-forestry, conservation of natural resources, and maintaining the quality of soil, air, and water.</p>
                            </li>
                            <li className='position-relative mb-3'>
                                <p>Eradicating hunger, poverty, and malnutrition.</p>
                            </li>
                            <li className='position-relative mb-3'>
                                <p>Providing disaster relief under applicable items in Schedule VII of the Companies Act, 2013 regarding CSR requirements.</p>
                            </li>
                            <li className='position-relative mb-3'>
                                <p>Promoting education, including special education, employment-enhancing vocational skills, and livelihood enhancement projects.</p>
                            </li>
                        </ul>
                   </div>
                </div>
             </div>
        </section>

        <section className='pt-0'>
                <div className="container">
                      <div className="row mt-3 mt-md-5">
                        <div className="col-md-3 col-6 mb-3 mb-md-3">
                            <div className="card h-100 rounded box_shadow overflow-hidden">
                                <div className="custom_img rounded-0">
                                    <img src='./images/csr1.jpg' className='rounded-0' alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6 mb-3 mb-md-3">
                            <div className="card h-100 rounded box_shadow overflow-hidden">
                                <div className="custom_img rounded-0">
                                    <img src='./images/csr2.jpg' className='rounded-0' alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6 mb-3 mb-md-3">
                            <div className="card h-100 rounded box_shadow overflow-hidden">
                                <div className="custom_img rounded-0">
                                    <img src='./images/csr3.jpg' className='rounded-0' alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6 mb-3 mb-md-3">
                            <div className="card h-100 rounded box_shadow overflow-hidden">
                                <div className="custom_img rounded-0">
                                    <img src='./images/csr4.webp' className='rounded-0' alt="" />
                                </div>
                            </div>
                        </div>
                      </div>
                </div>
        </section>
    </>
  )
}
