import { Aboutus } from "./components/Pages/Aboutus/Aboutus";
import { ContactUs } from "./components/Pages/Contact/ContactUs";
import { Home } from "./components/Pages/Home/Home";
import { Manufacturing } from "./components/Pages/BusinessOperations/Manufacturing";
import { Investors } from "./components/Pages/Investors/Investors";
import { ConnectionSystems } from "./components/Pages/Products/ConnectionSystems";
import { MechatronicsSystems } from "./components/Pages/Products/MechatronicsSystems";
import { Product } from "./components/Pages/Products/Product";
import { ResearchandDevelopment } from "./components/Pages/BusinessOperations/ResearchandDevelopment";
import { WorldwidePresence } from "./components/Pages/BusinessOperations/WorldwidePresence";
import { KeyClients } from "./components/Pages/KeyClients/KeyClients";
import { Sustainability } from "./components/Pages/Sustainability/Sustainability";
import { Csr } from "./components/Pages/CSR/Csr";
import { Careers } from "./components/Pages/Careers/Careers";
import { Faqs } from "./components/Pages/Careers/Faqs";
import { PressRelease } from "./components/Pages/PressRelease/PressRelease";
import { Comingsoon } from "./components/Pages/Comingsoon";
import { Disclosures } from "./components/Pages/Investors/Disclosures";
import { GroupCompanies } from "./components/Pages/GroupCompanies/GroupCompanies";
import { BoardofDirectors } from "./components/Pages/BoardofDirectors/BoardofDirectors";
import { Keyhighlights } from "./components/Pages/Keyhighlights/Keyhighlights";
import { RevenueBreakup } from "./components/Pages/RevenueBreakup/RevenueBreakup";
import { AwardsCertifications } from "./components/Pages/AwardCertifications/AwardsCertifications";
import { Beliefs } from "./components/Pages/Beliefs/Beliefs";
import Thankyou from "./components/Pages/Common/Thankyou";
import { Error404 } from "./components/Pages/Common/Error404";
import { ESGProfile } from "./components/Pages/ESGProfile/ESGProfile";
import { InvestorRelationsPlatform } from "./components/Pages/Investors/InvestorRelationsPlatform";
import { Disclosure_Under_Regulation_46 } from "./components/Pages/Investors/DisclosureUnderRegulation46ofSEBI-LODR";
import { BRSR } from "./components/Pages/Sustainability/BRSR";
import KeyEmployees from "./components/Pages/BoardofDirectors/KeyEmployees";
import KMPSMP from "./components/Pages/BoardofDirectors/KMPSMP";
import SeniorManagement from "./components/Pages/BoardofDirectors/SeniorManagement";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    index: true,
    path:'contact-us',
    element: <ContactUs />
  },
   {
     index: true,
       path: 'investor',
       element: <Investors />
   },
  {
    index: true,
    path:'aboutus',
    element: <Aboutus />
  },
  {
    index: true,
    path:'manufacturing',
    element: <Manufacturing />
  },
  {
    index: true,
    path:'products',
    element: <Product />
  },
  {
    index: true,
    path:'connective-products',
    element: <ConnectionSystems />
  },
  {
    index: true,
    path:'mechatronics-products',
    element: <MechatronicsSystems />
  },
  {
    index: true,
    path:'research-and-development',
    element: <ResearchandDevelopment />
  },
  {
    index: true,
    path:'worldwide-presence',
    element: <WorldwidePresence />
  },
   {
     index: true,
     path:'clients',
     element: <KeyClients />
   },
  // {
  //   index: true,
  //   path:'sustainability',
  //   element: <Sustainability />
  // },
  {
    index: true,
    path:'corporate-social-responsibilty',
    element: <Csr />
  },
  {
    index: true,
    path:'careers',
    element: <Careers />
  },
  {
    index: true,
    path:'faqs',
    element: <Faqs />
  },
   {
     index: true,
     path:'press-release',
     element: <PressRelease />
   },
  {
    index: true,
    path:'blogs',
    element: <Comingsoon />
  },
  {
    index: true,
    path:'term-condition',
    element: <Comingsoon />
  },
  {
    index: true,
    path:'privacy-policy',
    element: <Comingsoon />
  },
  {
    index: true,
    path:'/comingsoon',
    element: <Comingsoon />
  },
  // {
  //   index: true,
  //     path:'/disclosures',
  //   element: <Disclosures />
  // },
   {
     index: true,
     path:'group-companies',
     element: <GroupCompanies/>
   },
  {
    index: true,
      path: 'board-of-directors',
      element: <BoardofDirectors />
  },
  //  {
  //    index: true,
  //    path:'key-highlights',
  //    element: <Keyhighlights/>
  //  },
   {
     index: true,
     path:'revenue-breakup',
     element: <RevenueBreakup/>
   },
  {
    index: true,
    path:'awards-accreditations',
    element: <AwardsCertifications/>
  },
  // {
  //   index: true,
  //   path:'our-beliefs',
  //   element: <Beliefs/>
  // },
  {
    index: true,
    path:'thankyou',
    element: <Thankyou/>
  },
    {

    index: true,
    path:'*',
    element: <Error404/>
  },
  // {
  //   index: true,
  //   path:'/esg-profile',
  //   element: <ESGProfile/>
  // },
  {
    index: true,
    path:'/investor-relations-platform',
    element: <InvestorRelationsPlatform/>
  },
  {
    index: true,
    path:'/disclosure-under-regulation-46-of-SEBI-LODR',
    element: <Disclosure_Under_Regulation_46/>
  },
  // {
  //   index: true,
  //   path:'/brsr',
  //   element: <BRSR/>
  // },
  {
    index: true,
    path:'/key-employees-of-Subsidiaries',
    element: <KeyEmployees/>
  },
  {
    index: true,
      path:'/key-managerial-personnel',
    element: <KMPSMP/>
  },
  {
    index: true,
      path: '/senior-management',
      element: <SeniorManagement />
  }
];

export default AppRoutes;
