import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'

export const GroupCompanies = () => {
    return (
        <>
            <Jumbohead headertitle="Group Companies" Pagename=" Group Companies" bgurl='url("./images/page-header2.webp")' />

            <section>
                <div className="container">
                    <div className="table_wrap">
                    <div className="table-responsive" >
                        <table className="table table-borderless" >
                            <thead >
                                <tr >
                                    <th style={{backgroundColor:'var(--primary)'}} className='text-white py-3' scope="col">S. No.</th>
                                    <th  style={{backgroundColor:'var(--primary)'}} className='text-white py-3'  scope="col">Group Companies</th>
                                    <th  style={{backgroundColor:'var(--primary)'}} className='text-white py-3'  scope="col">Country </th>
                                    <th  style={{backgroundColor:'var(--primary)'}} className='text-white py-3'  scope="col">Auditor</th>
                                    <th  style={{backgroundColor:'var(--primary)'}} className='text-white py-3'  scope="col">Directors</th>
                                    <th  style={{backgroundColor:'var(--primary)'}} className='text-white py-3'  scope="col">Financial Year Closing</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="">
                                    <td scope="row"><b>1.</b></td>
                                    <td>Viney Corporation Private Limited</td>
                                    <td>India</td>
                                    <td>Grant Thornton</td>
                                    <td>Brijesh Aggarwal <br />
                                    Rakesh Kr Aggarwal</td>
                                    <td>FY ending March </td>
                                </tr>
                                <tr className="">
                                    <td scope="row"><b>2.</b></td>
                                    <td>Viney Auto Ancillary Private Limited </td>
                                    <td>India</td>
                                    <td>PDM and Company
                                    </td>
                                    <td>Brijesh Aggarwal <br />
                                    Priyanka Aggarwal <br />D.C. Sharma</td>
                                    <td>FY ending March </td>
                                </tr>
                                <tr className="">
                                    <td scope="row"><b>3.</b></td>
                                    <td>Vimercati SPA </td>
                                    <td>Italy</td>
                                    <td>Grant Thornton</td>
                                    <td>Brijesh Aggarwal <br />
                                    Mauro Pizi</td>
                                    <td>FY ending March </td>
                                </tr>
                                <tr className="">
                                    <td scope="row"><b>4.</b></td>
                                    <td>Vimercati East Europe SRL</td>
                                    <td>Romania</td>
                                    <td>Grant Thornton</td>
                                    <td>Huma Constantin </td>
                                    <td>FY ending March </td>
                                </tr>
                                <tr className="">
                                    <td scope="row"><b>5.</b></td>
                                    <td>Progind SRL</td>
                                    <td>Italy</td>
                                    <td>Grant Thornton</td>
                                    <td>Brijesh Aggarwal <br />
                                    Mauro Pizi</td>
                                    <td>FY ending March </td>
                                </tr>
                                <tr className="">
                                    <td scope="row"><b>6.</b></td>
                                    <td>Viney Corporation Middle East Limited</td>
                                    <td>UAE</td>
                                    <td>Affiniax</td>
                                    <td>Narisah Ladja Viay</td>
                                    <td>FY ending March </td>
                                </tr>
                                <tr className="">
                                    <td scope="row"><b>7.</b></td>
                                    <td>Viney Corporation East Europe SRL</td>
                                    <td>Romania</td>
                                    <td>NA</td>
                                    <td>Johny Thomas</td>
                                    <td>FY ending December </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </section>
        </>
    )
}
