import React from 'react'

const Iconbox = ({iconPath}) => {
    return (
        <>
            <div className="mt-5 mb-3 common_icon position-relative">
                <img src={`./images/icons/${iconPath}`} alt="" />
            </div>
        </>
    )
}

export default Iconbox