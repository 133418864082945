import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import Mainheader from '../Common/Mainheader'

export const Keyhighlights = () => {
  return (
    <>
        {/* <Jumbohead headertitle="Key Highlights" Pagename="Key Highlights" bgurl='url("./images/page-header2.webp")'/> */}

        <section className=''>
            <div className="container">
                <div className="col-12 text-center">
                    <Mainheader heading="Our Strategies" />
                </div>
                <div className="row">
                    <div className="col-md-4 mb-4">
                        <div class="d-flex gap-3 p-4 h-100 light-bg rounded" >
                            {/* <div class="flex-shrink-0 common_icon position-relative">
                                <img src="./images/icons/cable.svg" alt="" width="65" />
                            </div> */}
                            <div class="flex-grow-1 ms-3">
                                <h5 class="mt-0">Leverage existing customer relationships and diversify customer base:</h5>
                                <p>Our ongoing efforts to diversify our customer base involve not only taking efforts to acquire new customers, but also implementing cross-selling strategies to showcase our diverse product offerings to existing customers.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div class="d-flex gap-3 p-4 h-100 light-bg rounded" >
                            {/* <div class="flex-shrink-0 common_icon position-relative">
                                <img src="./images/icons/pie-chart.svg" alt="" width="65" />
                            </div> */}
                            <div class="flex-grow-1 ms-3">
                                <h5 class="mt-0">Grow inorganically through strategic acquisitions:</h5>
                               <p>We intend to focus on organic expansion through technological investments, business development skills, and nurturing customer relationships. Simultaneously, we will also remain vigilant for inorganic growth opportunities, evaluating acquisitions and strategic alliances that complement our existing technological prowess and financial profile.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div class="d-flex gap-3 p-4 h-100 light-bg rounded" >
                            {/* <div class="flex-shrink-0 common_icon position-relative">
                                <img src="./images/icons/team.svg" alt="" width="65" />
                            </div> */}
                            <div class="flex-grow-1 ms-3">
                                <h5 class="mt-0">Improve operational efficiency:</h5>
                                <p>We believe that we are steadfast in our pursuit of sustained cost reduction and operational excellence by embracing innovation, streamlining operations, and aligning our business with market trends. We continuously strive to reduce costs, ensuring our operations remain efficient and competitive.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
