import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import { Sustainbility } from '../Home/Sustainbility'

export const KeyClients = () => {
    const tier1Customer1 = [
        //{id:'1',imgURL:'./images/clients/new/Denso.png'},
        {id:'2',imgURL:'./images/clients/new/napino.png'},
        {id:'3',imgURL:'./images/clients/new/UNO-MINDA.png'},
        {id:'4',imgURL:'./images/clients/new/varoc.png'},
        {id:'5',imgURL:'./images/clients/new/jns.png'},
        {id:'6',imgURL:'./images/clients/new/lumax.svg'},
        //{id:'7',imgURL:'./images/clients/new/PhiniaLogo.webp'},
        {id:'8',imgURL:'./images/clients/new/sparkminda.png'},
        {id:'9',imgURL:'./images/clients/new/zenmo.webp'},
        // {id:'10',imgURL:'./images/clients/new/BMW.png'},
        // {id:'11',imgURL:'./images/clients/new/stellantis.png'},
    ]
    const tier1Customer = [
        {id:'1',imgURL:'./images/clients/new/ferrari.png'},
        {id:'2',imgURL:'./images/clients/new/bmw.png'},
        {id:'3',imgURL:'./images/clients/new/stellantis.png'},
        {id:'4',imgURL:'./images/clients/new/toyota.jpg'},
        {id:'5',imgURL:'./images/clients/new/renault.png'},
        {id:'6',imgURL:'./images/clients/new/hero.png'},
        {id:'7',imgURL:'./images/clients/new/rollsroyce.png'},
        {id:'8',imgURL:'./images/clients/new/iveco.png'},
        {id:'9',imgURL:'./images/clients/new/jaguar.png'},
        {id:'10',imgURL:'./images/clients/new/landrover.png'}
    ]
    const oemCustomer = [
        {id:'1',imgURL:'./images/clients/new/Denso.png'},
        {id:'2',imgURL:'./images/clients/new/pricol.png'},
        {id:'3',imgURL:'./images/clients/new/mothersen.png'},
        {id:'4',imgURL:'./images/clients/new/varoc.png'},
        {id:'5',imgURL:'./images/clients/new/borgwarner.png'},
        {id:'6',imgURL:'./images/clients/new/fedral.png'},
        {id:'7',imgURL:'./images/clients/new/fiem.png'},
        {id:'8',imgURL:'./images/clients/new/Delphi.png'},
        {id:'9',imgURL:'./images/clients/new/UNO-MINDA.png'},
        {id:'10',imgURL:'./images/clients/new/napino.png'},
        {id:'11',imgURL:'./images/clients/new/dy.jpg'},
        {id:'12',imgURL:'./images/clients/new/sparkminda.png'}
    ]
    const endCustomer = [
        {id:'1',imgURL:'./images/clients/new/suzuki.png'},
        {id:'2',imgURL:'./images/clients/new/tvs.png'},
        {id:'3',imgURL:'./images/clients/new/hundai.png'},
        {id:'4',imgURL:'./images/clients/new/hero.png'},
        {id:'5',imgURL:'./images/clients/new/tata.png'},
        {id:'6',imgURL:'./images/clients/new/honda.png'},
        {id:'7',imgURL:'./images/clients/new/bajaj.png'},
        // {id:'8',imgURL:'./images/keyclients/end_8.jpg'},
        // {id:'9',imgURL:'./images/keyclients/end_9.jpg'},
        // {id:'10',imgURL:'./images/keyclients/end_10.jpg'},
        // {id:'11',imgURL:'./images/keyclients/end_11.jpg'},
        // {id:'12',imgURL:'./images/keyclients/end-12.jpg'},
        // {id:'13',imgURL:'./images/keyclients/over_1b.jpg'},
        // {id:'14',imgURL:'./images/keyclients/over_2b.jpg'},
        // {id:'15',imgURL:'./images/keyclients/over_3b.jpg'},
        // {id:'16',imgURL:'./images/keyclients/over_4.jpg'},
        // {id:'17',imgURL:'./images/keyclients/over_5.jpg'},
        // {id:'18',imgURL:'./images/keyclients/over_6.jpg'},
        // {id:'19',imgURL:'./images/keyclients/over_7.jpg'},
        // {id:'20',imgURL:'./images/keyclients/over_8.jpg'},
        // {id:'21',imgURL:'./images/keyclients/over_9.jpg'},
        // {id:'22',imgURL:'./images/keyclients/over_10.jpg'},
        // {id:'23',imgURL:'./images/keyclients/over_11.jpg'},
        // {id:'24',imgURL:'./images/keyclients/over_12.jpg'},
        // {id:'25',imgURL:'./images/keyclients/over_13.jpg'},
        // {id:'26',imgURL:'./images/keyclients/over_14.jpg'}
    ]
    return (
        <>
            <Jumbohead headertitle="Key Clients" Pagename=" Key Clients" bgurl='url("./images/page-header2.webp")' />

            <section className='keyclients'>
                <div className="container">
                    {/* <div className="heading text-center mb-4" data-aos="fade-down" data-aos-duration="1500" data-aos-once='true'>
                        <h4 className="heading_span fw-medium">
                            Overview
                        </h4>
                        <h3>Our Key Clients</h3>
                        {/* <p className='mt-3 col-md-8 mx-auto'>Our key clients includes both Indian and global OEMs such as BMW, Ferrari, Jaguar, Renault, and Toyota in passenger vehicles segment, Denso, Delphi, Varroc, UNO Minda, and Spark Minda in the Tier 1 automotive components segment, and Suzuki, TVS, Hyundai, Tata, and Hero in the End Customers segment.</p>
                    </div> */}

                    <div className="row mt-4">
                                {tier1Customer1.map((item)=>
                                    <div className="col-md-3 col-6" key={item.id}>
                                        <div className="card p-3 position-relative rounded-0">
                                            <div className="custom_img rounded-0 ">
                                                <img src={item.imgURL} className='rounded-0' alt="" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                    {/* <ul className="nav nav-pills justify-content-center" id="myTab" role="tablist" data-aos="fade-up" data-aos-duration="2000" data-aos-once='true'>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link pe-3 me-3 active" id="OEM-tab" data-bs-toggle="tab" data-bs-target="#OEM" type="button" role="tab" aria-controls="OEM" aria-selected="false">OEM Customers</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link pe-3 me-3" id="twowheeler-tab" data-bs-toggle="tab" data-bs-target="#twowheeler" type="button" role="tab" aria-controls="twowheeler" aria-selected="true">Tier -1 Customers</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link pe-3" id="end-tab" data-bs-toggle="tab" data-bs-target="#end" type="button" role="tab" aria-controls="end" aria-selected="false">End Customers</button>
                        </li>
                    </ul>

                    <div className="tab-content" id="myTabContent" data-aos="fade-right" data-aos-duration="2500" data-aos-once='true'>
                        <div className="tab-pane fade" id="twowheeler" role="tabpanel" aria-labelledby="twowheeler-tab">
                            <div className="row mt-4">
                                {tier1Customer.map((item)=>
                                    <div className="col-md-3 col-6" key={item.id}>
                                        <div className="card p-3 position-relative rounded-0">
                                            <div className="custom_img rounded-0 ">
                                                <img src={item.imgURL} className='rounded-0' alt="" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="tab-pane fade show active" id="OEM" role="tabpanel" aria-labelledby="OEM-tab">
                            <div className="row mt-4">
                                {oemCustomer.map((item)=>
                                    <div className="col-md-3 col-6" key={item.id}>
                                        <div className="card p-3 position-relative rounded-0">
                                            <div className="custom_img rounded-0 ">
                                                <img src={item.imgURL} className='rounded-0' alt="" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="end" role="tabpanel" aria-labelledby="end-tab">
                            <div className="row mt-4">
                                {endCustomer.map((item)=>
                                    <div className="col-md-3 col-6" key={item.id}>
                                        <div className="card p-3 position-relative rounded-0">
                                            <div className="custom_img rounded-0 ">
                                                <img src={item.imgURL} className='rounded-0' alt="" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>

            {/*<Sustainbility/>*/}
        </>
    )
}
