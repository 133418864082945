import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick';
import Mainheader from '../Common/Mainheader';

export const History = () => {
    const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const historydata = [
    {
        id:1,
        sbtitle:'Incorporated',
        titleyear:'1992',
          imgSRC: './images/gurgaon.webp',
          pera1:'Our Company was Incorporated in 1992'
    },
    {
        id:4,
        sbtitle:'Commenced & Established',
        titleyear:'2005',
        imgSRC:'./images/gurgaon.webp',
        pera1:'Commenced  operations in Gurgaon',
        pera2:'Established a unit in Manesar, Gurgaon'
    },
    {
        id:5,
        sbtitle:'Commenced',
        titleyear:'2007',
        imgSRC:'./images/pune.webp',
        pera1:'Commenced operations in Pune'
    },
    {
        id:6,
        sbtitle:'Commenced',
        titleyear:'2009',
        imgSRC:'./images/history6.webp',
        pera1:'Commenced plant operations in Rudrapur, Uttarakhand'
    },
    {
        id:7,
        sbtitle:'Acquired',
        titleyear:'2011',
        imgSRC:'./images/history7.webp',
        pera1:'Acquired 70% shareholding in Vimercati S.p.A. (Subsidiary)'
    },
    {
        id:8,
        sbtitle:'Commenced',
        titleyear:'2012',
        imgSRC:'./images/history10.webp',
        pera1:'Commenced operations in Kathua-I Plant'
    },
    {
        id:9,
        sbtitle:'Commenced',
        titleyear:'2013',
        imgSRC:'./images/history11.webp',
        pera1:'Commenced operations in Hosur'
    },
    {
        id:10,
        sbtitle:'Acquired & Commenced',
        titleyear:'2015',
        imgSRC:'./images/history12.webp',
        pera1:'Acquired remaining 30% shareholding in Vimercati S.p.A. (Subsidiary)',
        pera2:'Commenced operation in Kundli'
    },
    {
        id:11,
        sbtitle:'Commenced',
        titleyear:'2020',
        imgSRC:'./images/history13.webp',
        pera1:'Commenced operations in Kathua-II Plant',
    },
    {
        id:12,
        sbtitle:'Acquisitions',
        titleyear:'2021',
        imgSRC:'./images/history14.webp',
        pera1:'New acquisitions in Europe of Progind SRL',
    },
    {
        id:13,
        sbtitle:'Subsidiaries',
        titleyear:'2023',
        imgSRC:'./images/history15.webp',
        pera1:'"Vimercati Viney De Mexico" one of our subsidiaries, was incorporated',
    },
  ]
  return (
    <section id='history' className="history" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
    <div className="container">
        <div>
                  <Mainheader heading='History of Our Company'/>
        </div>
            <div className="slider-container mt-5">
            <Slider asNavFor={nav2} arrows={false} fade='true' ref={slider => (sliderRef1 = slider)}>
                {historydata.map((item,index)=>
                    <div key={index}>
                        <div className="row">
                            <div className="col-md-6 mb-3 mb-md-0">
                                <div className="custom_img">
                                    <img src={item.imgSRC}  alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="heading">
                                    <h4 className="heading_span fw-medium">
                                        {item.sbtitle}
                                    </h4>
                                    <h2>{item.titleyear}</h2>
                                </div>
                                <p>{item.pera1}</p>
                                <p>{item.pera2}</p>
                            </div>
                        </div>
                    </div>
                )}
            </Slider>
            <Slider
                asNavFor={nav1}
                ref={slider => (sliderRef2 = slider)}
                slidesToShow={5}
                slidesToScroll={1}
                infinite={false}
                initialSlide={0}
                swipeToSlide={false}
                focusOnSelect={true}
                responsive = {[
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                      }
                    }
                  ]}
                className='my-5 history_year positio-relative'
            >
                {historydata.map((item,index)=>
                    <div className='year_number' key={index}>
                        <h3>{item.titleyear}</h3>
                    </div>
                )}
                
            </Slider>
            </div>
            </div>
        </section>
  )
}
