import React from 'react'
import { Link } from 'react-router-dom'

export const Error404 = () => {
  return (
    <>
        <div
                className="page-header theme-undefined"
                style={{
                    backgroundImage:'url("./images/page-header.webp")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundAttachment: "inherit",
                    height:'100px'
                }}
                >
                <div className="page-header-overlay" />
                <div className="page-header-wrapper">
                    <div className="container">
                    </div>
                </div>
            </div>
         <section className="error_404">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center">
                        <div className="error_bg">
                            <h1>404</h1>
                            <img src="./images/error.svg" alt="" />
                        </div>
                        <h4 className='mt-5'>Sorry, Page Not Found</h4>
                        <p>You can try to reload it some time later or browse our fabulous website right now!</p>
                        <Link to="/" className='theme-btn mt-1'>Back to Home</Link>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
