import React from 'react'

const Mainheader = ({heading , subHeading}) => {
    return (
        <>
            <div className="heading heading-3 mb-50" data-aos="fade-right" data-aos-duration="1500" data-aos-once='true' >
                <h2 className="heading__title">{heading}</h2>
                <span className="heading__subtitle">{subHeading}</span>
            </div>
        </>
    )
}

export default Mainheader