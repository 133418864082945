import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import Mainheader from '../Common/Mainheader'
import { Helmet } from 'react-helmet'

export const Disclosure_Under_Regulation_46 = () => {
  return (
    <>
        <Helmet>
            <title>Welcome to Viney Corporation Disclosure Under Regulation 46 of SEBI LODR</title>
            <meta name='description' content='Our commitment to sustainability & environmental responsibility for eco-friendly practices, sustainable products, & initiatives to reduce our carbon footprint.' />
        </Helmet>
          {/* <Jumbohead headertitle="Disclosure Under Regulation 46 of SEBI LODR" Pagename=" Investor Relations Platform" bgurl='url("./images/investors_bg.webp")' />*/}

       <div className="container-fluid">  
        <div className="iframes">
          </div>
          </div>
          <div className="container-fluid">
              <div className="parent-container" id='sebi_lodr'>
                    <iframe className='child-container' src='https://esg.churchgatepartners.com/login/companyprofile?id=3100340039003700240024004100530048004F004B0041004E0041004E00590041004100560041004E004900410053004800570049004E00490024002400' ></iframe>
                  
              </div>
        </div>
    </>
  )
}
