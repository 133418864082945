let baseUrl;
if (window.location.hostname.indexOf("react.") >= 0) {
    baseUrl = `${window.location.protocol}//${window.location.host}/${window.location.pathname.split('/')[1]}`;
} else {
    baseUrl = `${window.location.protocol}//${window.location.host}`;
}
export const virtualPath = baseUrl;

console.log(virtualPath, 'test')

export const ApiCall = (url, bodyData) => {
    const abortController = new AbortController();
    let data = fetch(`${virtualPath + url}`, {
        method: 'POST',
        body: JSON.stringify(bodyData),
        headers: {
            "Content-Type": "application/json"
        },
        signal: abortController.signal
    }).then((res) => res.json())
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err
        }).finally(() => {
            abortController.abort();
        });
    return data
};