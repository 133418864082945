import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import './components/Assets/custom.css';
import './components/Assets/responsive.css';
import { Layout } from './components/Layout/Layout';
import AOS from 'aos';
import { Loader } from './components/Pages/Common/Loader';
import { Backtop } from './components/Pages/Common/Backtop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [location]);

    useEffect(() => {
        AOS.init();
    }, [])

  return null;
}

export const App=()=> {
  const [ isloading, SetLoading] = useState(true);

  const { pathname } = useLocation();
  useLayoutEffect(() => {
    SetLoading(true);
    const timer = setTimeout(() => {
      SetLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [pathname]);

  
    useEffect(() => {
        //if (pathName === '/VineyCorporation2/esg-profile') {
        //    console.log('heloooooo')
        //    document.body.className = 'scrollfix';
        //} else {
        //    document.body.className = '';
        //}
        //return ()

        if (pathname === '/esg-profile' || pathname === '/brsr' || pathname === '/investor-relations-platform' || pathname === '/disclosure-under-regulation-46-of-SEBI-LODR') {
            document.body.classList.add('scrollfix'); // Add class
        } 
        // Cleanup: ensure class is removed when component unmounts
        return () => {
            document.body.classList.remove('scrollfix');
        };
    }, [pathname]);

    return (
     <>
            <ScrollToTop />
            <ToastContainer position="top-right"  newestOnTop={true} />
       <Backtop/>
          {/* { isloading ? <Loader/> :   */}
            <Layout>
                <Routes>
                  {AppRoutes.map((route, index) => {
                    const { element, ...rest } = route;
                    return <Route key={index} {...rest} element={element} />;
                  })}
                </Routes>
            </Layout>
          {/* // } */}
     </>
    );
}
