import React from 'react'
import Mainheader from '../Common/Mainheader'
import { Link } from 'react-router-dom'

export const Acquisition = () => {
  return (
    <>
        <section className='bg-light'>
            <div className="container">
                <div className="heading text-center">
                    <Mainheader heading='Acquisition' />
                    {/* <p>The unique opportunity presented by electrification aligns seamlessly with our commitment to building capabilities and products that integrate different elements effectively.</p> */}
                </div>
                <div className="row mt-3 mt-md-3">
                    <div className="col-md-12 mb-3 mb-md-0" data-aos="fade-right" data-aos-duration="2000" data-aos-once='true'>
                        <div className='p-3 border bg-white' style={{borderRadius:'10px'}}>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="custom_img">
                                        <img src="./images/italymilan.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <p style={{textAlign:'justify'}}>We have fully acquired Vimercati S.p.A in Italy in Fiscal
                                    2015. Subsequently, in Fiscal 2022, we extended our footprint with the acquisition of Progind SRL in Italy. These acquisitions were not without challanges, but our proactive strategies addressed structural issues stemming from legacy acquisitions, resulting in their successful integration.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6 mb-3 mb-md-0" data-aos="fade-left" data-aos-duration="2000" data-aos-once='true'>
                        <div className='p-3 border bg-white' style={{borderRadius:'10px'}}>
                            <div className="row">
                                <div className="col-4">
                                    <div className="custom_img">
                                        <img src="./images/commitment.webp" alt="" />
                                    </div>
                                </div>
                                <div className="col-8">
                                <p style={{textAlign:'justify'}}>Our commitment to growth goes beyond organic measures, as we have consistently demonstrated success through strategic acquisitions and alliances. We approach potential acquisitions with a meticulous evaluation process, considering multiple criteria such as access to new technology, new customer reach, diversification of product portfolios, entry into new markets, the scale of the acquisition, and its profitability. </p>
                                </div>
                            </div>
                            
                        </div>
                    </div> */}
                    {/* <div className="col-12 mt-5" data-aos="fade-up" data-aos-duration="2000" data-aos-once='true'>
                        <div className='p-3' style={{borderLeft:'2px solid var(--primary)'}}>
                            <p style={{textAlign:'justify'}}>In Fiscal 2011, we made a significant stride by acquiring the business of Vimercati Spa in Italy, focusing on switches. Subsequently, in Fiscal 2020, we extended our footprint with the acquisition of Progind Srl, a move that not only complemented our switch business but also included the strategic backward integration of manufacturing tools and dies essential for switch production. These acquisitions were not without challenges, but our proactive strategies addressed structural issues stemming from legacy acquisitions, resulting in their successful integration and improved financial health.</p>
                            <Link to="/manufacturing?international" className='theme-btn mt-1' >Read More</Link>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    </>
  )
}
