import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import { Helmet } from 'react-helmet'

const KeyEmployees = () => {
    const managementEurope=[
        {
            name:'Constantin Huma',
            desi:'Director  Vimercati East Europe SRL (Subsidiary Co.)',
            imgSRC:'./images/employees/Huma_Constantin.webp',
            pera:'Constantin Huma is a director of our Subsidiary, Vimercati East Europe SRL (“Vimercati"). He has been associated with Vimercati since 2008. He holds a diploma in Engineering, electric profile (Electrotechnica specialization) from the Socialist Republic of Romania, Ministry of Education and Education, Politechnic Institute.,GH, Asachi”Iasi, Faculty of Electrical Engineering and a diploma of Master Marketing Management from Romania Ministry of Education and Research, Technical University “Gh. Asachi” Iasi. He was previously associated with Tehnoton- Iasi.'
        },
        {
            name:'Mauro Pizi',
            desi:'Director and Chief Executive Officer, Vimercati S.p.A (Subsidiary Co.)',
            imgSRC:'./images/employees/Mauro_Pizi.webp',
            pera:'Mauro Pizi is a director and chief executive officer of our Subsidiary, Vimercati S.p.A. He has been associated with Vimercati S.p.A. since 2001. He holds a doctorate in Electronic Engineering from the Politechnico in Milan, Republic of Italy and a masters in Techological Choices and Business Management, with honours from MIP, Politechnico Di Milano. He was previously associated with Ametek Italy S.r.l., Magneti Marelli S.p.A. and Bayer Italy Spa.'
        },
        {
            name:'Riccardo Marra',
            desi:'Key Account Manager  Vimercati S.p.A (Subsidiary Co.)',
            imgSRC:'./images/employees/Riccardo_Marra.webp',
            pera:'Riccardo Marra is a permanent employee and the key account manager of our Subsidiary, Vimercati SpA (“Vimercati"). He holds a degree in PNI Mathematics Experimentation from Ministry of Education of University and Research He has been associated with Vimercati since 2016. He holds a diploma in scientific maturity, faculty of automotive engineering from Politecnico Di Turin. He was previously associated with Johnson Controls Automotive SRL and Sparco Spa.'
        },
        {
            name:'Lorenzo Pincelli',
            desi:'Vice-President Finance, Vimercati S.p.A (Subsidiary Co.)',
            imgSRC:'./images/employees/Lorenzo_Pincelli.webp',
            pera:'Lorenzo Pincelli is a Vice-President Finance of our Subsidiary, Vimercati S.p.A. He has been associated with Vimercati S.p.A. since 2023. He holds a degree in Business Economics with a specialization in Economics of industrial companies from Commercial University Luigi Bocconi. He was previously associated with Arthur Andersen Spa, Gianni Versace S.p.A, Diver SRL, MIT S.e.l, Luigi Lavazza S.p.A., Foodora Burgez SRL and 1000 Farmacie SPA.'
        }
    ]
  return (
      <>
          <Helmet>
              <title>Key Employees | Viney Corporation Limited</title>
              <meta name='description' content='Meet the dedicated key employees of Viney Corporation. Learn about the individuals who play a crucial role in shaping our companys vision, values, and success.' />
          </Helmet>
          <Jumbohead headertitle="Key Employees of Subsidiaries" Pagename=" Key Employees" bgurl='url("./images/page-header2.webp")' />

            <div className="container">
            {/* <div className="heading text-center mt-5">
                  <h4 className="heading_span fw-medium">
                    Europe
                  </h4>
                  <h3>Management Team</h3>
                </div> */}

                <div className="row mt-5">
                    {managementEurope.map((item,index)=>(
                        <div className="col-md-6 mb-3 mb-md-5" key={index}>
                            <div className="news_crd read_crd mb-2" data-aos="fade-up" data-aos-duration="2000" data-aos-once='true'>
                            <div className="custom_img">
                                <img src={item.imgSRC} className='rounded-3 w-100' alt="" />
                            </div>
                            <h4 className='mt-3 mb-0 fw-bold text-blue'>{item.name}</h4>
                                <figcaption className='mb-3 text-dark-50'><b>{item.desi}</b></figcaption>
                                <p style={{ textAlign: 'justify' }}>{item.pera}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
    </>
  )
}

export default KeyEmployees