import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ApiCall } from '../../utils';
import { Jumbohead } from '../Common/Jumbohead';
import { Helmet } from 'react-helmet';



export const ContactUs = () => {
    const [loader, setLoader] = useState(false)
    const [formDetails, setFormDetails] = useState({
        Name: "",
        EmailID: "",
        PhoneNumber: "",
        City: "",
        Message: ""
    })

    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormDetails(prv => ({ ...prv, [name]: value }))
    }

    const handleClick = async () => {

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formDetails.EmailID !== '' && !emailRegex.test(formDetails.EmailID)) {
            toast.error('Invalid email format');
            return;
        }
        try {
            setLoader(true)
            const res = await ApiCall("/api/WeatherForecast/SendMail", formDetails)

            if (res.status === "SUCCESS") {
                setLoader(false)
                toast.success(res.message)
                setFormDetails({
                    ...formDetails,
                    Name: "",
                    EmailID: "",
                    PhoneNumber: "",
                    City: "",
                    Message: ""
                })
            }
            else if (res.status === "ERROR") {
                setLoader(false)
                toast.error(res.message)
            }
        } catch (error) {
            toast.error('Some error occured, Please try again later!!');
            setLoader(false)
        }
    };

    return (
        <>
            <Helmet>
                <title>Contact us | Viney Corporation</title>
                <meta name='description' content='Whether you have any queries regarding our products or any other, our experts are always ready to solve your problem & provide customized solutions. Contact us.' />
            </Helmet>
            <Jumbohead headertitle="Contact Us" Pagename="contact us" />

            <section className='address_bar'>
                <div className="container">
                    <div className="heading text-center">
                        <h4 className="heading_span fw-medium">
                            Let's Get in Touch!
                        </h4>
                        <h3>
                            We’d Love to Hear From You
                        </h3>
                    </div>
                    <div className="row mt-n4">
                        <div className="col-md-6 col-xl-6 mt-4 wow fadeInUp">
                            <div className="card card-style10 border-0 border-radius-10 ms-4 h-100 shadow-box">
                                <div className="card-heading mt-4 position-relative">
                                    <h5 className="mb-0 text-white">Corporate Office</h5>
                                </div>
                                <div className="card-body p-1-9">
                                    <p className="mb-2">
                                        <span className='fw-bold'>VINEY CORPORATION LIMITED</span> <br /> Plot No. 42, Sector-3, IMT Manesar, Manesar, Gurgaon,<br /> Haryana-122052 India
                                    </p>
                                    <div className="mb-2">
                                        <i className="fa-regular fa-envelope text-blue me-2"></i><a href="mailto:cs@vineycorp.com">cs@vineycorp.com</a>
                                    </div>
                                    <div>
                                        <i className="fa-solid fa-phone text-blue me-2"></i><a href="tel:+911244567400">+91 124 4567400 </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-6 mt-4 wow fadeInUp">
                            <div className="card card-style10 border-0 border-radius-10 ms-4 h-100 shadow-box">
                                <div className="card-heading mt-4 position-relative">
                                    <h5 className="mb-0 text-white">Registered Office</h5>
                                </div>
                                <div className="card-body p-1-9">
                                    <p className="mb-1">
                                        <span className='fw-bold'>VINEY CORPORATION LIMITED</span> <br /> 35, Basement Community Center, Munirka Marg, <br /> Basant Lok, Vasant Vihar, New Delhi-110057.
                                    </p>
                                    <div className="mb-2">
                                        <i className="fa-regular fa-envelope text-blue me-2"></i><a href="mailto:cs@vineycorp.com">cs@vineycorp.com</a>
                                    </div>
                                    <div>
                                        <i className="fa-solid fa-phone text-blue me-2"></i><a href="tel:011-49981612">011-49981612 </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-xl-4 mt-4 wow fadeInUp">
                            <div className="card card-style10 border-0 border-radius-10 ms-4 h-100 shadow-box">
                            <div className="card-heading mt-4 position-relative">
                                <h5 className="mb-0 text-white">Viney Corporation Pvt Ltd.</h5>
                            </div>
                            <div className="card-body p-1-9">
                                <p className="mb-1">
                                    
                                </p>
                            </div>
                            </div>
                        </div> */}
                    </div>

                </div>
            </section>

            <section className='pt-0 d-none'>
                <div className="container">
                    <div className="heading text-center">
                        <h4 className="heading_span fw-medium">
                            Our Locations
                        </h4>
                        <h3>
                            Our presence around the globe
                        </h3>
                    </div>
                    <div className="position-relative map_world">
                        <img src="./images/world.svg" alt="" />
                        <div className="country_dot dot1">
                            <div className="tooltip_name">India</div>
                            <div className="content_country">
                                <p className="mb-2">
                                    <span className='fw-bold'>VINEY CORPORATION PRIVATE LIMITED</span> <br /> Plot No - 93,94, Phase - V, HSIIDC, Kundli, Sonipat, Haryana - 131028, INDIA.
                                </p>
                                <div className="mb-2">
                                    <i className="fa-regular fa-envelope text-blue me-2"></i><a href="mailto:info.m@vineycorp.com">info.m@vineycorp.com</a>
                                </div>
                                <div>
                                    <i className="fa-solid fa-phone text-blue me-2"></i><a href="tel:+91-124-4567400">+91-124-4567400 </a>
                                </div>
                            </div>
                        </div>
                        <div className="country_dot dot2">
                            <div className="tooltip_name">Italy</div>
                            <div className="content_country">
                                <p className="mb-2">
                                    <span className='fw-bold'>Italy</span> <br />
                                    <b className='text-blue'>Plant Milan (Vimercati Spa), Plant Turin (Progind Srl)</b>
                                </p>
                                <div className="mb-2">
                                    <i className="fa-regular fa-envelope text-blue me-2"></i><a href="mailto:info.m@vineycorp.com">info.m@vineycorp.com</a>
                                </div>
                                <div>
                                    <i className="fa-solid fa-phone text-blue me-2"></i><a href="tel:+91-124-4567400">+91-124-4567400 </a>
                                </div>
                            </div>
                        </div>
                        <div className="country_dot dot3">
                            <div className="tooltip_name">Romania</div>
                            <div className="content_country">
                                <p className="mb-2">
                                    <span className='fw-bold'>Romania</span> <br />
                                    <b className='text-blue'>Plant Bacau (Vimercati East Europe Srl)</b>
                                </p>
                                <div className="mb-2">
                                    <i className="fa-regular fa-envelope text-blue me-2"></i><a href="mailto:info.m@vineycorp.com">info.m@vineycorp.com</a>
                                </div>
                                <div>
                                    <i className="fa-solid fa-phone text-blue me-2"></i><a href="tel:+91-124-4567400">+91-124-4567400 </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='form'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="heading">
                                <h4 className="heading_span fw-medium">
                                    Send a Message
                                </h4>
                                <h3>
                                    We are Open for your queries, please complete the form below.
                                </h3>
                            </div>
                            <div className="row mt-3 mt-md-5">
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="name" className="form-label mb-1 fw-medium">Name<sup className='text-danger'>*</sup></label>
                                    <input
                                        value={formDetails.Name}
                                        onChange={handleChange}
                                        className="form-control"
                                        id="Name"
                                        name="Name"
                                        pattern="[a-zA-Z\s]+"
                                        placeholder="Enter your full name"
                                        type="text"
                                    />

                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="email" className="form-label mb-1 fw-medium">EmailID<sup className='text-danger'>*</sup></label>
                                    <input
                                        value={formDetails.EmailID}
                                        className="form-control"
                                        id="EmailID"
                                        onChange={handleChange}
                                        name="EmailID"
                                        pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                        placeholder="mymail@gmail.com"
                                        type="text"
                                    />

                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="phone_number" className="form-label mb-1 fw-medium">Phone Number<sup className='text-danger'>*</sup></label>
                                    <input
                                        value={formDetails.PhoneNumber}
                                        maxLength="10"
                                        className="form-control numberOnlyWithoutSpace"
                                        id="MobileNumber"
                                        onChange={handleChange}
                                        name="PhoneNumber"
                                        pattern="[0-9]{10}"
                                        placeholder="Your phone no"
                                        type="number"
                                    />

                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="your_city" className="form-label mb-1 fw-medium">Your City<sup className='text-danger'>*</sup></label>
                                    <input
                                        value={formDetails.City}
                                        className="form-control"
                                        id="City"
                                        onChange={handleChange}
                                        name="City"
                                        pattern="[a-zA-Z\s]+"
                                        placeholder="Your City"
                                        type="text"
                                    />

                                </div>
                                <div className="col-12 mb-3">
                                    <label htmlFor="message" className="form-label mb-1 fw-medium">Message<sup className='text-danger'>*</sup></label>
                                    <textarea
                                        value={formDetails.Message}
                                        className="form-control"
                                        cols="7"
                                        id="Comments"
                                        onChange={handleChange}
                                        name="Message"
                                        placeholder="Your Message"
                                        rows="4"
                                    ></textarea>

                                </div>
                                <div className="col-12">
                                    <button onClick={() => handleClick()} style={loader ? { cursor: "not-allowed" } : { cursor: "pointer" }} disabled={loader ? true : false} className='theme-btn border-0 py-2 px-3 mt-3'>{loader ? "Sending..." : "Send"}</button>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <iframe className='rounded' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d224697.0173195178!2d76.7507131!3d28.362148!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3e81ab29f78f%3A0xfa541fbbc345ce15!2sViney%20Corporation%20Limited!5e0!3m2!1sen!2sin!4v1728469596681!5m2!1sen!2sin" width="100%" style={{ boxShadow: 'rgba(0, 0, 0, 0.05) 0px 15px 64px 0px', height: '100%', border: '1px solid var(--primary)' }}></iframe>
                            {/*<iframe className='rounded' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3493.859171432447!2d77.13849131519908!3d28.872806582323495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390dab996e1272d1%3A0x17b8035b88a0c4df!2sViney+Corporation+Private+Ltd!5e0!3m2!1sen!2sin!4v1532289130199" width="100%" style={{ boxShadow: 'rgba(0, 0, 0, 0.05) 0px 15px 64px 0px', height: '100%', border: '1px solid var(--primary)' }}></iframe>*/}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
