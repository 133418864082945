import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import { Link } from 'react-router-dom'

export const PressRelease = () => {
    const Pressdata = [ 
        {
            id:'1',
            date:'26,April',
            imgURL:'./images/news1.webp',
            heading:'Tata Motors opens new CV parts warehouse in Guwahati',
            pera:'The modern facility is fully digitalized and spans across 1 lakh square feet, stocking spare parts for the entire commercial vehicle portfolio, the company said.'
        },
        {
            id:'2',
            date:'26,April',
            imgURL:'./images/news2.webp',
            heading:'Auto parts industry registers record drop in revenue in FY20',
            pera:'India’s auto parts industry recorded a drop in revenue after five years, hit by a prolonged slowdown in the domestic market and the global impact of the coronavirus pandemic.'
        },
        {
            id:'3',
            date:'26,April',
            imgURL:'./images/news3.webp',
            heading:'Auto vendors go vocal for local',
            pera:'The immediate focus is largely on localizing mechanical parts such as forged and machined components'
        },
        {
            id:'4',
            date:'26,April',
            imgURL:'./images/news4.webp',
            heading:'Car parts maker Valeo posts lower first-quarter sales on weak EV demand',
            pera:'To face those uncertainties, Valeo has been careful to no longer have plants only specialized in high voltage, but able to address both hybrids and EVs, Perillat said.'
        },
        {
            id:'5',
            date:'26,April',
            imgURL:'./images/news5.webp',
            heading:'Back Turnover of auto component industry likely to decline by 14-18% in FY 21: Icra',
            pera:'Revenues of auto component manufacturing industry could decline by 14-18% in the current fiscal, owing to a sharp decline in domestic vehicle sales and exports..'
        },
    ]
  return (
    <>
         <Jumbohead headertitle="Press Releases " Pagename="Press Releases" bgurl='url("./images/page-header6.webp")' />

        <section style={{backgroundColor:'#f4f4f4'}}>
            <div className="container">
                <div className="heading text-center">
                    <h3 className='text-blue'>Press Releases and News</h3>
                    <h4>Media</h4>
                    <p>We have been recognised by our customers and industry organisations for our excellence in business.</p>
                </div>
                <div className="row mt-3 mt-md-5">
                    {Pressdata.map((item)=>
                        <div className="col-md-4 mb-3" key={item.id}>
                            <div className="news_crd read_crd mb-2" data-aos="fade-up" data-aos-duration="2000" data-aos-once='true'>
                                <div className="custom_img box-shadow">
                                <img src={item.imgURL} className='rounded-3 w-100' alt="" />
                                </div>
                                <figcaption className='mt-3 text-dark-50'>{item.date}</figcaption>
                                <Link className='text-decoration-none text-dark' to='#'><h5 className='my-3 fw-bold'>{item.heading}</h5></Link>
                                <p>{item.pera}</p>
                                <a className='readmorebtn' href="">Read More <i className="fa-solid fa-arrow-right-long"></i></a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    </>
  )
}
