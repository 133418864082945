import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <>
            {/* <div className="CTA">
            <div className="container">
                <h4 className='text-white font-bold'>Your feedback is incredibly valuable to us.</h4>
                <h6 className='text-white font-bold my-3'>Want to know more about Viney Corporation? Get in touch with us here</h6>
                <a className="button-animation me-3">
                    Contact Us
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </a>
            </div>
        </div> */}
            <footer className='bg-dark pt-5'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 mb-5 mb-md-0">
                            <div className="navbar-brand mb-3">
                                <img src="./images/logo.svg" className='img-fluid' alt="" />
                            </div>
                            <h6 className='text-white fw-medium'>One of the leading manufacturer, supplier and exporter of automotive components</h6>
                            <div className='d-flex align-items-center mt-4 gap-2'>
                                <Link target='_blank' to='https://www.facebook.com/p/Viney-Corporation-Private-Limited-61551524937733/?_rdr'>
                                    <div className="d-flex justify-content-center align-items-center soical-icon">
                                        <img src="./images/icons/facebook.svg" width='15px' alt="" />
                                    </div>
                                </Link>
                                <Link target='_blank' to='https://www.instagram.com/vineycorporation_1989/'>
                                    <div className="d-flex justify-content-center align-items-center soical-icon">
                                        <img src="./images/icons/instagram.webp" width='15px' alt="" />
                                    </div>
                                </Link>
                                {/* <Link to='#'>
                                <div className="d-flex justify-content-center align-items-center soical-icon">
                                    <img src="./images/icons/twitter.svg" width='15px' alt="" />
                                </div>
                            </Link> */}
                                <Link target='_blank' to='https://www.linkedin.com/company/viney-corporation-limited/?originalSubdomain=in'>
                                    <div className="d-flex justify-content-center align-items-center soical-icon">
                                        <img src="./images/icons/linkedin.svg" width='15px' alt="" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3 col-6 mb-5 mb-md-0">
                            <div className="ftr-heading">
                                <h5 className='text-blue font-bold'>About Viney Corp.</h5>
                            </div>
                            <ul className='list-unstyled ftr_link'>
                                <li id=""><Link to="/aboutus">Our Story</Link></li>
                                {/*<li id=""><Link to="/corporate-social-responsibilty">CSR</Link></li>*/}
                                <li id=""><Link to="/research-and-development">R&D</Link></li>
                                <li id=""><Link to="/board-of-directors">Management & Leadership</Link></li>
                                <li id=""><Link to="/awards-certifications">Awards and Achievements</Link></li>
                            </ul>
                        </div>
                        {/* <div className="col-md-3">
                        <div className="ftr-heading">
                            <h5 className='text-blue font-bold'>Innovation & Technology</h5>
                        </div>
                        <ul className='list-unstyled ftr_link'>
                            <li id=""><Link to="#">Overview</Link></li>
                            <li id=""><Link to="#">R&D Center</Link></li>
                            <li id=""><Link to="#">Test & Measurement Labs</Link></li>
                            <li id=""><Link to="#">Avatar-The Design Studio</Link></li>
                        </ul>
                    </div> */}
                        {/* <div className="col-md-2">
                        <div className="ftr-heading">
                            <h5 className='text-blue font-bold'>Investor Relation</h5>
                        </div>
                        <ul className='list-unstyled ftr_link'>
                            <li id=""><Link to="#">Contact Us</Link></li>
                            <li id=""><Link to="#">Join Us</Link></li>
                            <li id=""><Link to="#">Media</Link></li>
                        </ul>
                    </div> */}
                        <div className="col-md-3 col-6">
                            <div className="ftr-heading">
                                <h5 className='text-blue font-bold'>Quick Links</h5>
                            </div>
                            <ul className='list-unstyled ftr_link'>
                                <li id=""><Link to="/">Home</Link></li>
                                <li id=""><Link to="/products">About Products</Link></li>
                                {/* <li id=""><Link to="/esg-profile">Sustainability</Link></li> */}
                                {/* <li id=""><Link to="/investor">Investors</Link></li> */}
                                <li id=""><Link to="/term-condition">Term & Conditions</Link></li>
                                <li id=""><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to='/careers' >Careers</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <div className="ftr-heading">
                                <h5 className='text-blue font-bold'>Corporate Office</h5>
                            </div>
                            <div className='d-flex gap-2'>
                                <div><i className='bx bxs-buildings bx-tada' style={{ fontSize: '20px', color: 'var(--primary)' }} ></i></div>
                                <span className='text-white-50'>Plot No. 42, Sector-3, IMT Manesar, Manesar, Gurgaon, Haryana-122052 India</span>
                            </div>
                            <div className='d-flex gap-2'>
                                <div><i className='bx bx-mail-send bx-tada' style={{ fontSize: '20px', color: 'var(--primary)' }} ></i></div>
                                <Link to='mailto:cs@vineycorp.com' className='text-white-50'>cs@vineycorp.com</Link>
                            </div>
                            <div className='d-flex gap-2'>
                                <div><i className='bx bx-phone-call bx-tada' style={{ fontSize: '20px', color: 'var(--primary)' }} ></i></div>
                                <Link to='tel:+911244567400' className='text-white-50'>+91 124 4567400</Link>
                            </div>
                        </div>
                    </div>
                    <div className="ftr-bottom py-4 my-5 border-top border-secondary">
                        <div className="d-block d-md-flex justify-content-between align-items-center">
                            <p className='small text-white-50 m-0'>© Copyright 2024 - Viney Corporation Limited. All Rights Reserved.</p>
                            <p className='small m-0' style={{ color: '#5f5f5f' }}>Website Design and Development by <Link to='https://www.sagarinfotech.com/'>SIPL</Link></p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
