import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import { Helmet } from 'react-helmet'

const SeniorManagement = () => {
    const managementIndia=[
        {
            name:'Sachin Jain',
            desi:'Operations Head (Switches & Components)',
            imgSRC:'./images/team.webp',
            pera:`Sachin Jain is the Operations Head (Switches & Components) of our Company. He holds a bachelor's degree in technology,
                    Mechanical (Lateral Entry) from Janardan Rai Nagar Rajasthan Vidyapeeth University and a Diploma course in Mechanical
                    Engineering (Specialization in production engineering) from D.J. Polytechnic Baraut, Meerut, Board of Technical Education,
                    Uttar Pradesh. He has an experience of 14 years in the automotive industry and has been associated with our Company since
                    February 1, 2010.`
        },
        {
            name:'Akshat Agarwal',
            desi:'Corporate Head (Purchase Department)',
            imgSRC:'./images/employees/kmp/Akshat_Kumar.webp',
            pera: 'Akshat Agarwal is the Corporate Head (Purchase Department) of our Company. He holds a degree in bachelors of technology (Electronics & Instrumentation Engineering) from U.P. Technical University, Lucknow and an executive post graduate diploma in International Business from Indian Institute of Foreign Trade (Deemed University), school of International Business. He has 18 years of experience in the automotive manufacturing industry. He has been associated with our Company since April 17, 2023. He has previously been associated with Industrial Electronics, Maruti Suzuki India Limited and Honda Motorcycle & Scooter India Private Limited.'
        },
        {
            name:'Neeraj Bhargav',
            desi:'R & D & Tool Room Head',
            imgSRC:'./images/employees/kmp/Niraj_Bhargav.webp',
            pera:'Neeraj Bhargav is the R & D & Tool Room Head of our Company. He holds a bachelors of technology (Mechanical Engineering) part time from Jawaharlal Nehru Technological University, Kakinada. He holds a diploma degree in Mechanical Engineering with specialization – Tools & Die Engineering from State Board of Technical Education, Haryana and a Master of Technology in industrial safety from National Institute of Technology, Kurukshetra. He has been associated with our Company since August 8, 2012. He has 32 years of experience in automotive manufacturing industry. He has previously been associated with Kangaro (India) Private Limited, Precision Pipes and Profiles Company Limited, Mindarika Private Limited, Subros Limited and JBJ Technologies Limited.'
        },
        {
            name:'Gaurav Kumar Sharma',
            desi:'Corporate HR Head',
            imgSRC:'./images/team.webp',
            pera:'Gaurav Kumar Sharma is the Corporate HR Head of our Company. He holds a bachelors degree in science from Chaudhary Charan Singh University, Meerut and a degree in master of science from Chaudhary Charan Singh University, Meerut. He also holds a Master of Social Work from Kalinga University, Raipur (C.G.). and an advanced diploma in computer software technology from ET&T Corporation Limited. He has 6 years of experience in automotive manufacturing industry. He has been associated with our Company since June 15, 2020. He was previously associated with Almex Industries Private Limited, Rico Casting Limited and United Breweries Limited.'
        },
        {
            name:'Ashoo Sachdeva',
            desi:'Operations Head (Wiring Harness and Wires)',
            imgSRC: './images/employees/kmp/Ashoo_Sachdeva.webp',
            pera: `Ashoo Sachdeva is the Operations Head (Wiring Harness and Wires) of our Company. He holds a bachelor’s degree in mechanical engineering from University of Mysore. He has 26 years of experience in the automotive manufacturing industry. He has been associated with our Company since December 1, 2001. He was previously associated with Horizon Industrial Products Private Limited and Excel Infotech Limited.`
        }
    ]
  return (
      <>
          <Helmet>
              <title>Senior Management | Viney Corporation Limited</title>
              <meta name='description' content='Our Senior Management who drive our strategic vision & operational excellence. Guiding the company toward sustained growth & uphold the highest standards' />
          </Helmet>
          <Jumbohead headertitle="Senior Management" Pagename="Senior Management" bgurl='url("./images/page-header2.webp")' />
          <section className="pt-5">
              <div className="container">
                  <div className="row mt-5 smp">
                      {managementIndia.map((item, index) => (
                          <div className="col-md-6 mb-3 mb-md-5" key={index}>
                              <div className="news_crd read_crd mb-2" data-aos="fade-up" data-aos-duration="2000" data-aos-once='true'>
                                  <div className="custom_img">
                                      <img src={item.imgSRC} className='rounded-3 w-100' alt="" />
                                  </div>
                                  <h4 className='mt-3 mb-0 fw-bold text-blue'>{item.name}</h4>
                                  <figcaption className='mb-3 text-dark-50'><b>{item.desi}</b></figcaption>
                                  <p style={{ textAlign: 'justify' }}>{item.pera}</p>
                              </div>
                          </div>
                      ))}
                  </div>
              </div>
          </section>
    </>
  )
}

export default SeniorManagement