import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import ReactApexChart from 'react-apexcharts'

export const RevenueBreakup = () => {
    const vineyIndia = [
        {
        heading:'Viney India Business',
        series: [6, 4, 11, 10, 37, 6, 10, 16],
        options: {
            labels: ['Federal-Mogul', 'Fiem Industries', 'JNS Instruments', 'Napino Auto', 'Others', 'Pricol', 'Vimarcati', 'Minda Group'],
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            }],
        },
    },
    {
        heading:'Revenue Breakdown of Viney Group : By Products',
        series: [73, 27],
        options: {
            labels: ['Mechatronic', 'Connecting Systems'],
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            }],
        },
    },
    {
        heading:'Viney Europe Business',
        series: [40, 6, 33, 2, 9, 10],
        options: {
            labels: ['Fiat', 'Peugeot', 'BMW', 'Iveco', 'Renault', 'Others'],
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            }],
        },
    },
    {
        heading:'Revenue Breakdown: By Geography',
        series: [70, 26, 4],
        options: {
            labels: ['Overseas', 'Domestic', 'Exports'],
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            }],
        },
    }
];
    

  return (
    <>
        <Jumbohead headertitle="Revenue Breakup" Pagename=" Revenue Breakup" bgurl='url("./images/page-header2.webp")' />

        <section>
            <div className="container">
                <div className="row">
                    {vineyIndia.map((item,index)=>(
                        <div className="col-md-6 mb-3" key={index}>
                            <div className="light-bg p-3 rounded h-100">  
                                <h5 className='text-blue mb-3'>{item.heading}</h5>
                                <ReactApexChart options={item.options} series={item.series} type={item.options.chart.type} width={500} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    </>
  )
}
