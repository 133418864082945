import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export const Careers = () => {
    return (
        <>
            <Helmet>
                <title>Careers | Viney Corporation</title>
                <meta name='description' content='Viney Corporation is one the leading companies in providing connective and mechatronic systems products across the globe. Contact us for innovative solutions.' />
            </Helmet>
            <Jumbohead headertitle="Careers " Pagename=" Careers " bgurl='url("./images/page-header5.jpg")' />

            {/* <section>
                <div className="container">
                    <div className="heading text-center">
                        <h3 className='text-blue'>Overview</h3>
                        <p>Our company employs a skilled workforce dedicated to precision and innovation. With over 300 employees, we ensure robust quality and efficiency across our facilities. Our commitment to excellence drives us to deliver superior products and solutions to our global clientele.</p>
                        <p>We conduct regular training sessions for our employees and provide e-learning modules on 3M, 5S, and kaizen through our intranet, which are available in various local languages. We have a Human Resource Helpdesk that visits each plant on a rotational basis to meet employees and address their concerns, if any. We have policies to enhance employee welfare, including the Policy and Procedure to Prevent Sexual Harassment at Work, Conveyance and Meal Allowance Policy, Policy Manual for Employee Life Cycle, and Code of Business Ethics.</p>
                    </div>
                </div>
            </section> */}

            <section className='pt-2 d-none'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 my-auto order-2 order-md-1" data-aos="fade-right" data-aos-duration="1500" data-aos-once='true'>
                            <div className="heading mb-3">
                                <h4 className="heading_span fw-medium">
                                    Life at Viney Corp.
                                </h4>
                                <p style={{textAlign:'justify'}}>At Viney Corp., we are more than a technology company; we are a team where innovation thrives. Our culture encourages creativity and challenges the norm, empowering our people to drive the future.</p>
                                <h3>Diversity and Inclusion</h3>
                            </div>
                            <p style={{textAlign:'justify'}}>At Viney Corp., we believe diversity fuels innovation. Our team's varied experiences enrich our service offerings and foster a welcoming workplace. Here, your skills, ideas, and perspectives matter more than your background. Our <b className='text-blue'>Employee Value Proposition (EVP)</b> underscores unique offerings and values, distinguishing us as an employer of choice for both prospective and current team members.</p>
                        </div>
                        <div className="col-md-6 order-1 order-md-2 mb-3 mb-md-0" data-aos="fade-left" data-aos-duration="1500" data-aos-once='true'>
                            <div className="custom_img">
                                <img src="./images/careers.webp" alt="Viney Corpoartion" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section style={{ backgroundColor: '#f4f4f4' }} id='openings'>
                <div className="container">
                    <div className="heading text-center">
                        <h4>Join Our Team : Shape the Future with Us</h4>
                    </div>
                    <div className="row mt-3 mt-md-5">
                        <div className="col-md-6">
                            <div className="custom_img">
                                <img src="./images/join.webp" alt="" />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="card">
                                <h4 className='text-blue'>Work With us</h4>
                                <p>We are always eager to meet energetic and talented professionals who would like to join our team. If you wish to be considered for any future positions, kindly submit your CV at</p>
                                <a href="mailto:corporatehrhead@vineycorp.com">corporatehrhead@vineycorp.com</a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="accordion main-accordian mt-5" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <h6 className='m-0'>Technical Lead – Telematics</h6>
                                    
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" >
                                <div className="accordion-body">
                                    <p className='text-blue fw-bold'>Job Responsibility</p>
                                    <ul className="ps-4 mt-3 achiev_list">
                                        <li className='position-relative mb-3'>Experience in Setting vision/mission and objective of the hardware team based on Business Unit financial goal and technology goals. </li>
                                        <li className='position-relative mb-3'>Experience in budgeting and monitoring the spending effectively </li>
                                        <li className='position-relative mb-3'>Sound experience in working with Vendors/Engineering service providers and effectively tracking the deliverables </li>
                                        <li className='position-relative mb-3'>Achieve project QCT (Quality, Cost and Time) targets and OTD, FTR target % in all the milestones. </li>
                                        <li className='position-relative mb-3'>Review dashboard and metrics of the teams periodically and take preventive/corrective actions. Improvement plan definition and tracking to closure. </li>
                                        <li className='position-relative mb-3'>Create and Manage resource allocation as per the need of the project delivery/milestones </li>
                                        <li className='position-relative mb-3'>Team management and effective tracking experience </li>
                                        <li className='position-relative mb-3'>Motivate and guide the team to surpass individual KPI targets </li>
                                        <li className='position-relative mb-3'>Skill assessment for next level managers, Leads &amp; team members </li>
                                        <li className='position-relative mb-3'>Schedule the training plan as per the skill GAP of the individual members/ Project requirement. </li>
                                        <li className='position-relative mb-3'>Project Management: Microsoft Project Planner, V Cycle based development process /Agile methodology/SCRUM, Bug Tracker tool, SVN etc.. </li>
                                        <li className='position-relative mb-3'>Cohesive working with cross function team/manufacturing plant/service providers </li>
                                        <li className='position-relative mb-3'>Excellent customer management skills</li>
                                    </ul>

                                   <div className="d-md-flex gap-5">
                                        <div>
                                            <p className='text-blue fw-bold mt-4'>Qualification</p>
                                            <p className='m-0'> <b>B.E / B.</b> Tech in ECE/EEE/EIE</p>
                                            <p><b>M. E / M.</b> Tech in ECE/EEE/EIE</p>
                                        </div>
                                        <div className='ms-md-5'>
                                            <p className='text-blue fw-bold mt-4'>Experience</p>
                                            <p className='m-0'> <b>8 – 10 years</b></p>
                                        </div>
                                   </div>
                                   <Link to="#" className='theme-btn mt-3'>Apply Now</Link>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>
            </section>
        </>
    )
}
