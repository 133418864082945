import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import { History } from './History'
import { BoardofDirectors } from './BoardofDirectors'
import { GroupCompanies } from './GroupCompanies'
import { AwardsandCertifications } from './AwardsandCertifications'
import { Achievements } from './Achievements'
import Iconbox from '../Common/Iconbox'
import { Keyhighlights } from '../Keyhighlights/Keyhighlights'
import Mainheader from '../Common/Mainheader'
import { Helmet } from 'react-helmet'

export const Aboutus = () => {
  return (
    <>
        <Helmet>
              <title>About us | Viney Corporation Limited</title>
              <meta name='description' content='Viney Corporation Limited. is a leading company in connective and mechatronic systems which are the parts of vehicles. Know about our mission & values today.' />
        </Helmet>

        <Jumbohead headertitle="About Us" Pagename="about us"/>

        <History/>

        <section className='beliefs d-none' id='beliefs'> 
            <div className="container">
                <div className="row justify-content-center">
                    <div className="heading text-center col-md-9" data-aos="fade-down" data-aos-duration="1500" data-aos-once='true'>
                        <h3 className='text-blue'>Our Beliefs</h3>
                        <p>“We believe the company's strong foundation and strategic focus on growth position it well for future success. With a diverse product portfolio and emphasis on operational excellence, we see continued potential for market expansion.
                        ”</p>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className="col-md-4 text-center" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                        <Iconbox iconPath='mission.svg' />
                        <h5>Mission</h5>
                        <p>We partner with leading auto companies with a commitment to excellence in quality and all areas of business. We strive to meet and exceed customer expectations.</p>
                    </div>
                    <div className="col-md-4 text-center" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                        <Iconbox iconPath='vision.svg' />
                        <h5>Vision</h5>
                        <p>To achieve profitable growth as the premier global supplier, offering innovative value-added services to our customers with competitive advantages.</p>
                    </div>
                    <div className="col-md-4 text-center" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                        <Iconbox iconPath='quality.svg' />
                        <h5>Quality Policy</h5>
                        <p>We are committed to achieving customer satisfaction by manufacturing quality products as per customer specifications. Our established quality system is continually enhanced through the involvement of all employees, ensuring we always leap towards excellence.</p>
                    </div>
                </div>
            </div>
        </section>

        <section id={"company-profile"} className="about light-bg">
            <div className="container">
                <div className="row mt-3">
                    <div className="col-sm-12 col-md-12 col-lg-7 order-2 order-lg-1" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                          <Mainheader heading='Overview' subHeading='One of the leading manufacturer, supplier and exporter of automotive components' />
                    <div className="about__text mt-30">
                        {/* <p>Established in 1992, we are one of India's leading manufacturers and exporters of automotive components. We specialize in the production of connective products like wiring harnesses, fuse boxes, cables, and connectors, as well as mechatronic products such as steering wheel switches, gear-shifting paddles, brake pedal switches, and sensors. We cater to a wide range of vehicles, including two-wheelers, passenger vehicles, and commercial vehicles.</p>
                        <p>Our diversified portfolio enables us to serve both internal combustion engine (ICE) vehicles and electric vehicles (EVs), positioning us as a segment-agnostic player. We are a Tier-1 supplier for OEMs across the automotive industry and has established a strong global presence, with manufacturing facilities in India, Italy, Romania, and Mexico. We have also achieved backward integration in key areas, manufacturing critical raw materials like cables and plastic components in-house, enhancing cost efficiency and maintaining high product quality.</p>
                        <p>We have fostered long-standing relationships with prominent automotive manufacturers, including global giants such as BMW, Renault, and Fiat, as well as Indian leaders like Maruti Suzuki, Hero, TVS, and Bajaj. We acquired Vimercati S.P.A., a leading Italian manufacturer of mechanical and mechatronic switches, in 2015, expanding our capabilities and product offerings in the European market.</p> */}
                    <p>We are one of the leading manufacturer, supplier and exporter of automotive components i.e., Mechatronic Products and Connective Products (Source: CRISIL Report). We are a Tier-1 supplier for OEMs for both Mechatronic Products and Connective Products, and a Tier-2 supplier and Tier-3 supplier for OEMs for Connective Products. As of March 31, 2024, one out of every six two-wheelers sold in India are fitted with one of our device wiring harness (Source: CRISIL Report).</p>
                        <ul className='ps-4 achiev_list mb-3'>
                            <li className='position-relative mb-3'>As of March 31, 2024, we are amongst one of the few Indian switch manufacturers with in-house capabilities to design and manufacture switches catering to both high-end vehicles and mass-market vehicles (Source: CRISIL Report). </li>
                            <li className='position-relative mb-3'>We have a diverse product range of products. As of March 31, 2024, we had a product range of over 500 Mechatronic Products and over 2,500 Connective Products. Our focus is on developing switches, wiring harness, terminals, connectors and other
components that align with the changing preferences and requirements of customers. We have developed extensive in-house capabilities for embedded systems and application software.</li>
                        </ul>
                        {/* <p>We are one of India’s leading companies specializing in designing, manufacturing, and distributing connective and mechatronic systems, critical components in vehicles. We are the leading manufacturer of device wiring harnesses for the automotive sector in India in terms of revenue.</p>
                        <p>We are the largest supplier of two-wheeler device harnesses and components in India.</p>
                        <p>We are the only Indian switch manufacturer with in-house capabilities to design and manufacture switches for both high-end and mass-market vehicles.</p>
                        <p>Our product portfolio extends across multiple vehicle segments, from luxury to mass-market platforms. This versatility allows us to transition products initially developed for the luxury market to cater to mass-market platforms, showcasing our adaptability and market responsiveness. We are a segment-agnostic, technology-driven company guided by the pillars of technology, efficient processes, skilled personnel, and robust governance. We have focused on innovations and offer customized solutions to our customers.</p> */}
                    </div>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-5 order-1 order-lg-2 mb-4 mb-md-0" data-aos="fade-left" data-aos-duration="1500" data-aos-once='true'>
                    <div className="custom_img">
                        <img src="./images/about2.webp" alt="about" className="img-fluid" />
                    </div>
                    </div>
                    <div className="col-12 order-last mt-3" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                        <div className="card p-3 br-10" style={{borderStyle:'dashed',borderColor:'var(--primary)'}}>
                            {/* <p>In terms of operational excellence, we have implemented advanced technology and lean manufacturing techniques across our production facilities. Our R&D capabilities have allowed us to continually innovate, offering cutting-edge solutions that meet the evolving demands of the automotive industry. With a focus on high-margin businesses, we have maintained strong financial performance, consistently achieving superior EBITDA margins compared to industry peers.</p>
                            <p>We operate nine manufacturing facilities, with a significant presence in low-cost countries like India and Romania. This global footprint has allowed the us to cater to both domestic and international markets, further diversifying our revenue streams. Through strategic investments and partnerships, we aim to continue our growth trajectory and solidify our position as a global leader in automotive component manufacturing.</p> */}
                            {/* <h5>In-House Excellence:</h5>
                            <p>We have in-house software development, product development, and production facilities. Our policy on value engineering, backward integration of operations, and lean organizational structure guarantee value delivery at the very competitive prices. </p> */}

                            {/* <h5>Competitive Edge:</h5>
                            <p>The company has earned its reputation for offering solutions that are tailored to the needs of the client, supported by the company’s experience in product development and implementation. This is a competitive advantage since there are very few players in the market who will be able to offer similar services. </p> */}

                            <h5>Our Strengths</h5>
                            <p>One of the leading manufacturer, supplier and exporter of automotive components with a diverse range of products.</p>
                            <ul>
                             <li className="mb-3">
                             <b>Strong and long-term customer relationships with cross selling opportunities and increased global presence</b>
                             <p> We have fostered strong and long-term relationships with our customers over the years. We typically have long-term agreements with Mechatronic Products’ customers for a period of three to seven years.</p>
                             </li>
                             <li className="mb-3">
                             <b>Segment agnostic business model with ability to adapt and innovate through R&D</b>
                             <p>Most of our Mechatronic Products and Connective Products are supplied for both ICE vehicles and EVs. Hence, we are a segment agnostic technology-driven company. </p>
                             </li>
                             <li className="mb-3">
                             <b>Backward integrated of operations driving cost efficiencies</b>
                             <p>We have been able to achieve backward integration in several of our products in both the Mechatronic Products and Connective Products. For instance, we manufacture raw materials such as cables and plastic molding components in-house, leading to
                             reduced raw material costs.</p>
                             </li>
                            </ul>
                        </div>
                        {/* <p>We have in-house software development, product development, and production capabilities. We offer quality products at optimized costs due to our focus on value engineering, backward integration of operations, and maintaining a lean organizational structure.</p>
                        <p>We are a recognized brand in the industry, and our ability to provide customized solutions with a proven track record in product development and execution catering to the diverse needs of our customer base gives us a competitive advantage, as there are very few competitors with similar capabilities.</p>
                        <p>Through our extensive experience, established product portfolio, and proven track record, we believe we are well-positioned to provide products and services to existing and new geographies. Since our inception, our business has consistently expanded, strategically establishing facilities in close proximity to our customers.</p> */}
                    </div>
                </div>
            </div>
        </section>
        <Keyhighlights/>

        {/* <BoardofDirectors/> */}

        {/* <GroupCompanies/> */}

        <AwardsandCertifications/>

        {/* <Achievements/> */}
    </>
  )
}
