import React from 'react'
import { Link } from 'react-router-dom'

export const Sustainbility = () => {
    return (
        <section id="banner4" className="banner sustainbility pt-120 pb-120 bg-img">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-9 offset-lg-3 col-xl-7 offset-xl-5" data-aos="fade-up" data-aos-duration="1000" data-aos-once='true'>
                    <div className="banner-content">
                    <div className="heading heading-3 heading-white mb-30">
                        {/* <span className="heading__subtitle text-white fw-normal">Building The Future, Restoring The Past</span> */}
                                <h3 className="heading__title text-white">Sustainability</h3>
                        <p className="heading__desc text-white">Environmental requirements imposed by our government will continue to have an effect on us and our operations. We believe
                        that we have complied, and will continue to comply, with all applicable environmental laws, rules and regulations.</p>
                    </div>
                            {/* <Link to="/esg-profile" className="readmore text-white mt-3">Read More <i className="fa-solid fa-arrow-right-long text-white ms-2"></i></Link> */}
                    </div>
                </div>
                </div>
            </div>
        </section>
    )
}
