import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import Mainheader from '../Common/Mainheader'
import { Helmet } from 'react-helmet'

export const ConnectionSystems = () => {
    const wiring = [
        {
            id: 1, imgurl: './images/products/wiringharness.jpg', prodName: 'Wiring Harness'
        },
        {id:2,imgurl:'./images/products/terminal.png',prodName:'Terminal'},
        {id:3,imgurl:'./images/products/terminal1.png',prodName:'Terminal'},
        {id:4,imgurl:'./images/products/connector.png',prodName:'Connectors'},
        {id:5,imgurl:'./images/products/rubberseal.png',prodName:'Rubber Seals'},
        {id:6,imgurl:'./images/products/cables.png',prodName:'Cables '},
        {
            id: 7, imgurl: './images/2W/BLINKERWH.jpg', prodName: 'Blinker Wiring Harness'
        },
        {
            id: 8, imgurl: './images/2W/FRONTSTOPWH.jpg', prodName: 'Front Stop Switch Wiring Harness'
        },
        { id: 9, imgurl: './images/2W/HEADLAMPWH.jpg', prodName: 'Head Lamp Wiring Harness' },
        { id: 10, imgurl: './images/2W/Lockwh.jpg', prodName: 'Ignition Lock Wiring Harness' },
        { id: 11, imgurl: './images/2W/MAGNETOWH.jpg', prodName: 'Magneto Wiring Harness' },
        { id: 12, imgurl: './images/2W/REARSTOPWH.jpg', prodName: 'Rear Stop Switch Wiring Harness' },
        { id: 13, imgurl: './images/2W/SELFSTARTWH.jpg', prodName: 'Self-Start Switch Wiring Harness' },
        { id: 14, imgurl: './images/2W/SIDESTRANDWH.jpg', prodName: 'Side Stand Switch Wiring Harness' },
        {id:15,imgurl:'./images/2W/SPEEDOMETERWIRE.jpg',prodName:'Speedo Meter Wiring Harness'},
        {id:16,imgurl:'./images/2W/SPEEDSENSORWH.jpg',prodName:'Speed Sensor Wiring Harness'},
        {id:17,imgurl:'./images/2W/SWITCHWH.jpg',prodName:'Handlebar Switch Wiring Harness'},
        {id:18,imgurl:'./images/2W/TAILLAMPWH.jpg',prodName:'Tail Lamp Wiring Harness'},
        {id:19,imgurl:'./images/2W/TANKUNITWH.jpg',prodName:'Tank Unit Wiring Harness'},
        {id:20,imgurl:'./images/2W/USBCHARGER.jpg',prodName:'USB Charger'},
    ]
    const terminals = [
        {imgurl:'./images/2w/01.jpg'},
        {imgurl:'./images/2w/02.jpg'},
        {imgurl:'./images/2w/03.jpg'},
        {imgurl:'./images/2w/04.jpg'},
        {imgurl:'./images/2w/05.jpg'},
        {imgurl:'./images/2w/06.jpg'},
        {imgurl:'./images/2w/07.jpg'}
    ]
    const connectors = [
        {imgurl:'./images/2w/08.jpg'},
        {imgurl:'./images/2w/09.jpg'},
        {imgurl:'./images/2w/10.jpg'},
        {imgurl:'./images/2w/11.jpg'},
        {imgurl:'./images/2w/12.jpg'},
        {imgurl:'./images/2w/13.jpg'},
        {imgurl:'./images/2w/14.jpg'},
        {imgurl:'./images/2w/15.jpg'},
        {imgurl:'./images/2w/16.jpg'},
        {imgurl:'./images/2w/17.jpg'},
        {imgurl:'./images/2w/18.jpg'},
        {imgurl:'./images/2w/19.jpg'},
        {imgurl:'./images/2w/20.jpg'},
    ]
  return (
    <>
        <Helmet>
              <title>Connective Products | Viney Corporation Limited</title>
            <meta name='description' content='From wiring harnesses to connectors, our Connective Products are designed to enhance vehicle performance & reliability. Contact us for innovative solutions.' />
        </Helmet>
        <Jumbohead headertitle="Connective Products" Pagename=" Connective Products" bgurl='url("./images/page-header3.webp")' />

        <section className='connectionsystems'>
            <div className="container">
                <div className="heading text-center" data-aos="fade-down" data-aos-duration="1500" data-aos-once='true'>
                    <p className='my-3'>We design and manufacture a wide variety of Connective Products components, such as wiring harnesses, terminals, connectors , wires, cables, fuse boxes.</p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-10 text-center my-5" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                        <img src='./images/bikeimg.png' className="img-fluid" alt='bike' />
                    </div>
                </div>
                {/* <div className="row mt-3 mt-my-5">
                   <div className="col-md-4 mb-3 mb-md-0" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                        <div className="card">
                            <div className="content_crd">
                                <h6>Our Leadership</h6>
                                <p>Our leadership in Connective Products, particularly in the manufacture of device wiring harnesses and related components, sets us apart as the leading manufacturer of device wiring harnesses for the automotive sector in India.</p>
                            </div>
                        </div>
                   </div>
                   <div className="col-md-4 mb-3 mb-md-0" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                        <div className="card">
                            <div className="content_crd">
                                <h6>Our Established</h6>
                                <p>Our established position in Connective Products is supported by a backward integration strategy, where we manufacture a comprehensive array of components integral to the production of wiring harnesses. This includes terminals, connectors, rubber seals, molded parts, cables, and cable sleeves.</p>
                            </div>
                        </div>
                   </div>
                   <div className="col-md-4 mb-3 mb-md-0" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                        <div className="card">
                            <div className="content_crd">
                                <h6>Our Vertical Integration</h6>
                                <p>Our vertical integration extends to the designing and manufacturing of tools and dies, showcasing our self-sufficiency. With a focus on design and process control, we consistently deliver superior value propositions, reflected in industry-leading margins compared to our counterparts in the same segments in India.</p>
                            </div>
                        </div>
                      </div>
                  </div> */}
            </div>
        </section>

        <section className='connection_product light-bg'>
            <div className="heading text-center mb-5">
                <Mainheader heading='Connective Products' />
            </div>
            <div className="container">
                {/* <div className="col-12" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                    <ul className="nav nav-tabs" id="myTab2" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="wiring-tab" data-bs-toggle="tab" data-bs-target="#wiring" type="button" role="tab" aria-controls="wiring" aria-selected="true">Device Wiring Harnesses</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="terminals-tab" data-bs-toggle="tab" data-bs-target="#terminals" type="button" role="tab" aria-controls="terminals" aria-selected="false" >Terminals</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="connectors-tab" data-bs-toggle="tab" data-bs-target="#connectors" type="button" role="tab" aria-controls="connectors" aria-selected="false" >Connectors</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="rubber-tab" data-bs-toggle="tab" data-bs-target="#rubber" type="button" role="tab" aria-controls="rubber" aria-selected="false" >Rubber Seals</button>
                        </li>
                        <li className="nav-item" role="presentation">
                           <button className="nav-link" id="cables-tab" data-bs-toggle="tab" data-bs-target="#cables" type="button" role="tab" aria-controls="cables" aria-selected="false" >Cables</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="relays-tab" data-bs-toggle="tab" data-bs-target="#relays" type="button" role="tab" aria-controls="relays" aria-selected="false" >Relays</button>
                        </li>
                    </ul>
                </div> */}
                  <div className="col-12 mt-3 mt-md-5" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                <div className="tab-content" id="myTabContent" >
                        <div className="tab-pane fade show active" id="wiring" role="tabpanel" aria-labelledby="twowheeler2-tab">
                            <p>Device Harness connects individual devices or components to the main wiring harness or directly to power sources</p>
                            <div className="row">
                                {wiring.map((item)=>
                                    <div className="col-md-3 col-6 mb-4" key={item.id}>
                                        <div className="card p-3 box_shadow">
                                            <div className="custom_img">
                                                <img src={item.imgurl} alt="" />
                                            </div>
                                            <h6 className='text-center text-secondary'>{item.prodName}</h6>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                          
                        <div className="tab-pane fade" id="terminals" role="tabpanel" aria-labelledby="terminals-tab">
                            <p>Terminals is an essential component of the automotive electrical system, responsible for transmitting current, signals, and important data.</p>
                              <div className="row">
                                  {terminals.map((item ,index) => {
                                      return (
                                          <div className="col-md-3 col-6 mb-4" key={index}>
                                              <div className="card p-3 box_shadow">
                                                  <div className="custom_img">
                                                      <img src={item.imgurl} alt="" />
                                                  </div>
                                                  {/*<h6 className='text-center text-secondary'>Terminal</h6>*/}
                                              </div>
                                          </div>
                                      )
                                  })}
                                </div>
                        </div>

                        <div className="tab-pane fade" id="connectors" role="tabpanel" aria-labelledby="connectors-tab">
                            <p>Connectors are responsible for insulation and proper transmission of electric power and information to different components within the car</p>
                              <div className="row">
                                  {connectors.map((item, index) => {
                                      return (
                                          <div className="col-md-3 col-6 mb-4" key={index}>
                                              <div className="card p-3 box_shadow">
                                                  <div className="custom_img">
                                                      <img src={item.imgurl} alt="" />
                                                  </div>
                                                  {/*<h6 className='text-center text-secondary'>Terminal</h6>*/}
                                              </div>
                                          </div>
                                      )
                                  })}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
