import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import Mainheader from '../Common/Mainheader'
import { Helmet } from 'react-helmet'

export const WorldwidePresence = () => {
  return (
    <>

        <Helmet>
            <title>Our Worldwide Presence | Viney Corporation</title>
            <meta name='description' content='With our global reach and presence in key markets around the world, we make sure reliable service, local support, and timely delivery of high-quality products.' />
        </Helmet>
            <Jumbohead headertitle="Worldwide Presence" Pagename=" Worldwide Presence" bgurl='url("./images/page-header2.webp")'/>

            <section>
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-6 mb-3 mb-md-0" data-aos="fade-right" data-aos-duration="1500" data-aos-once='true'>
                            <div className="custom_img">
                            <img src="./images/WWP.webp" alt="Viney Corpoartion" />
                            </div>
                        </div> */}
                        <div className="col-md-12" data-aos="fade-left" data-aos-duration="1500" data-aos-once='true'>
                            <Mainheader heading='Overview' />
                           
                            <p>The diverse application of our products enables us to cater to a number of categories of customer groups such as two-wheeler
and three-wheeler vehicles, passenger vehicles and commercial vehicles. We are a Tier-1 supplier for a large Indian automotive
company, providing the main wiring harness for their flexi-vehicle platform. Our customers are typically OEMs and Tier-1
suppliers of two-wheeler vehicles, passenger vehicles and commercial vehicles. Our OEM customers include Indian as well as
global OEMs. Our products are supplied and distributed across India, Europe, USA and Brazil. Our OEM customers typically
have stringent timelines, review procedures, and product standards. </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className='customers' style={{backgroundColor:'#f4f4f4'}}>
                <div className="container">
                      <div className="heading mb-4" data-aos="fade-down" data-aos-duration="1500" data-aos-once='true'>
                            <h3>
                                Esteemed Customers
                            </h3>
                            <h4 className="heading_span fw-medium">
                                Over the years, we have continually expanded our customer base, fostering long-term relationships.
                            </h4>
                          <p>Serving esteemed customers like [Napino] since [●], [Pricol] since [●], [Minda Industries] since [●], and [Minda Corporation] since [●], [Denso] since [●].</p>
                      </div>
                      <div className="row mt-3 mt-md-5">
                        <div className="col-md-3 col-6" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                            <div className="card rounded border-0 box_shadow overflow-hidden">
                                <div className="custom_img rounded-0">
                                    <img src='./images/napino.png' className='rounded-0' alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                            <div className="card rounded border-0 box_shadow overflow-hidden">
                                <div className="custom_img rounded-0">
                                    <img src='./images/Pricol.png' className='rounded-0' alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                            <div className="card rounded border-0 box_shadow overflow-hidden">
                                <div className="custom_img rounded-0">
                                    <img src='./images/minda.png' className='rounded-0' alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                            <div className="card rounded border-0 box_shadow overflow-hidden">
                                <div className="custom_img rounded-0">
                                    <img src='./images/minda_corp.png' className='rounded-0' alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                            <div className="card rounded border-0 box_shadow overflow-hidden">
                                <div className="custom_img rounded-0">
                                    <img src='./images/Denso.png' className='rounded-0' alt="" />
                                </div>
                            </div>
                        </div>
                      </div>

                      <p className='mt-5'>There has been addition of new customers in the recent years, including [Federal Mogul Ltda] in Brazil in 2017, and [●] reflecting our global diversification efforts. </p>

                        <div className="row">
                            <div className="col-md-3 col-6" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                                <div className="card rounded border-0 box_shadow overflow-hidden">
                                    <div className="custom_img rounded-0">  
                                        <img src='./images/Federal-Mogul_Logo.png' className='rounded-0' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
        </section> */}
    </>
  )
}
