import React, { useEffect, useState } from "react";
import { Jumbohead } from "../Common/Jumbohead";
import { Indiamap } from "./Indiamap";
import { Jammu } from "./Indiamanufacturing/Jammu";
import { Haryana } from "./Indiamanufacturing/Haryana";
import { Delhi } from "./Indiamanufacturing/Delhi";
import { Uttranchal } from "./Indiamanufacturing/Uttranchal";
import { Maharashtra } from "./Indiamanufacturing/Maharashtra";
import { TamilNadu } from "./Indiamanufacturing/TamilNadu";
import { InternationalManu } from "./International/InternationalManu";
import { useLocation } from "react-router-dom";
import Mainheader from "../Common/Mainheader";
import { Helmet } from "react-helmet";

export const Manufacturing1 = () => {
  const location = useLocation();
  const url = location.pathname;

  const indiadata = [
    {
      id: 1,
      imgurl: "./images/indiamanufacturing/item1.jpg",
      prodName: "Automatic Switch Assembly Lines",
    },
    {
      id: 2,
      imgurl: "./images/indiamanufacturing/item2.jpg",
      prodName: "Plating Plant",
    },
    {
      id: 3,
      imgurl: "./images/indiamanufacturing/item3.jpg",
      prodName: "Assembly Lines",
    },
    {
      id: 4,
      imgurl: "./images/indiamanufacturing/item4.jpg",
      prodName: "Automatic Wire Cutting & Crimping",
    },
    {
      id: 5,
      imgurl: "./images/indiamanufacturing/item5.jpg",
      prodName: "Injection Moulding",
    },
    {
      id: 6,
      imgurl: "./images/indiamanufacturing/item6.jpg",
      prodName: "Pad Printing",
    },
    {
      id: 7,
      imgurl: "./images/indiamanufacturing/item7.jpg",
      prodName: "Press Shop",
    },
    {
      id: 8,
      imgurl: "./images/indiamanufacturing/item8.jpg",
      prodName: "Robotic Paint Shop",
    },
  ];

  // const facilities = [
  //   {
  //     imgURL : './images/delhi.png',
  //     area:'Kundli, Delhi',
  //     products:'Terminals, Connectors, Switches'
  //   },
  //   {
  //     imgURL : './images/about2.webp',
  //     area:'Manesar, Haryana',
  //     products:'Terminals, Connectors, Wiring Harness'
  //   },
  //   {
  //     imgURL : './images/maharashtra.png',
  //     area:'Pune, Maharashtra',
  //     products:'Terminals, Connectors, Wiring Harness, Rubber Seal'
  //   },
  //   {
  //     imgURL : './images/tamilnadu.jpg',
  //     area:'Hosur, Tamil Nadu',
  //     products:'Wiring Harness'
  //   },
  //   {
  //     imgURL : './images/rudrapur.jpg',
  //     area:'Rudarpur, Uttranchal',
  //     products:'Wiring Harness'
  //   },
  //   {
  //     imgURL : './images/kathua.png',
  //     area:'Kathua-1, Kathua-2 Jammu',
  //     products:' Terminals, Connectors, Wiring Harness'
  //   }
  // ]

  const [activeMap, setActiveMap] = useState(0);

  return (
    <>
      <Helmet>
        <title>Our Manufacturing Facility | Viney Corporation Limited</title>
        <meta
          name="description"
          content="Our Modern manufacturing capabilities ensure the production of high-quality, reliable connecting & mechatronic systems. Contact us for more information."
        />
      </Helmet>
      <Jumbohead
        headertitle="Manufacturing"
        Pagename=" Manufacturing"
        bgurl='url("./images/page-header2.webp")'
      />

      <section className="clients bg-white pb-0">
        <div className="container">
          <ul
            className="nav nav-tabs"
            id="myTab"
            role="tablist"
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link pe-3 me-3 ${location.search === "?international" ? "" : "active"
                  }`}
                id="india-tab"
                data-bs-toggle="tab"
                data-bs-target="#india"
                type="button"
                role="tab"
                aria-controls="india"
                aria-selected="true"
              >
                India
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link pe-3 me-3 ${location.search === "?international" ? "active" : ""
                  }`}
                id="International-tab"
                data-bs-toggle="tab"
                data-bs-target="#International"
                type="button"
                role="tab"
                aria-controls="International"
                aria-selected="false"
              >
                International
              </button>
            </li>
          </ul>
        </div>

        <div
          className="tab-content"
          id="myTabContent"
          data-aos="fade-up"
          data-aos-duration="2500"
          data-aos-once="true"
        >
          <div
            className={`tab-pane fade ${location.search === "?international" ? "" : "show active"
              }`}
            id="india"
            role="tabpanel"
            aria-labelledby="india-tab"
          >
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <Mainheader heading="Manufacturing Facilities" />
                    <p>
                      We have established four manufacturing facilities in India
                      (located at Kathua, UT of J&K; Manesar, Haryana; Kundli,
                      Haryana and Pune, Maharashtra), two manufacturing
                      facilities in Italy (located at Milan and Turin) and one
                      manufacturing facility and one internal production unit,
                      both located in Romania (located at Bacău). We also have
                      three assembly units in India (located at Kathua, UT of
                      J&K; Hosur, Tamil Nadu and Rudrapur, Uttarakhand).Please
                      see below details of certifications obtained by our
                      manufacturing facilities and assembly units in India.{" "}
                    </p>
                  </div>
                  <div
                    className="col-md-6 order-1 order-md-2 mb-3 mb-md-0"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                    data-aos-once="true"
                  >
                    {/* <div className="custom_img">
                      <img src="./images/rd5.webp" alt="Viney Corpoartion" />
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
            <section className="facilities">
              <div className="container">
                <div className="col-12 text-center">
                  <Mainheader
                    heading="Facilities"
                    subHeading="Manufacturing Platforms / Assembly Units"
                  />
                </div>

                <div className="row map_tab mt-3 mt-md-5">
                  <div
                    className="col-md-6 col-12 connection_product"
                    data-aos="fade-right"
                    data-aos-duration="2000"
                    data-aos-once="true"
                  >
                    <ul
                      className="nav nav-tabs d-flex d-lg-none india_scroll"
                      id="myTab2"
                      role="tablist"
                    >
                      <li className="nav-item" onClick={() => setActiveMap(0)}>
                        <button
                          className={`nav-link ${activeMap === 0 ? "active" : ""
                            }`}
                        >
                          Jammu
                        </button>
                      </li>
                      <li className="nav-item" onClick={() => setActiveMap(1)}>
                        <button
                          className={`nav-link ${activeMap === 1 ? "active" : ""
                            }`}
                        >
                          Haryana
                        </button>
                      </li>
                      <li className="nav-item" onClick={() => setActiveMap(3)}>
                        <button
                          className={`nav-link ${activeMap === 3 ? "active" : ""
                            }`}
                        >
                          Uttarakhand
                        </button>
                      </li>
                      <li className="nav-item" onClick={() => setActiveMap(4)}>
                        <button
                          className={`nav-link ${activeMap === 4 ? "active" : ""
                            }`}
                        >
                          Maharashtra
                        </button>
                      </li>
                      <li className="nav-item" onClick={() => setActiveMap(5)}>
                        <button
                          className={`nav-link ${activeMap === 5 ? "active" : ""
                            }`}
                        >
                          Tamil Nadu
                        </button>
                      </li>
                    </ul>
                    <div className="d-none d-lg-block position-relative">
                      <Indiamap
                        setActiveMap={setActiveMap}
                        activeMap={activeMap}
                      />
                    </div>
                  </div>
                  <div
                    className="col-md-6 mt-4 mt-md-0"
                    data-aos="fade-left"
                    data-aos-duration="2000"
                    data-aos-once="true"
                  >
                    {activeMap === 0 ? (
                      <Jammu />
                    ) : activeMap === 1 ? (
                      <Haryana />
                    ) : activeMap === 2 ? (
                      <Delhi />
                    ) : activeMap === 3 ? (
                      <Uttranchal />
                    ) : activeMap === 4 ? (
                      <Maharashtra />
                    ) : activeMap === 5 ? (
                      <TamilNadu />
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/* <div className="row facilites_mf mt-5">
                  {facilities.map((item,index)=>(
                    <div className="col-md-4 mb-4" key={`facilites${index}`}>
                      <div className="custom_img mb-3">
                          <img src={item.imgURL} alt="" />
                      </div>
                      <h4 className='text-blue'>{item.area}</h4>
                      <p><b>Products:- <span className='text-blue'>{item.products}</span> </b></p>
                    </div>
                  ))}
                </div> */}

                {/* <div className="position-relative map_world" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                        <img className='d-none d-lg-block' src="./images/world2.svg" alt="" />
                        <div className="country_dot dot1">
                            <div className="content_country">
                            <h6>
                                <img className='me-2' src="./images/icons/india.svg" width='35px' alt="" />India
                                </h6>
                                <p className='m-0'><img src="./images/icons/pin.svg" width='20px' alt="" /> Manesar, Haryana</p>
                                <p className='m-0'><img src="./images/icons/pin.svg" width='20px' alt="" /> Kathua Unit-I, Jammu & Kashmir</p>
                                <p className='m-0'><img src="./images/icons/pin.svg" width='20px' alt="" /> Kathua Unit-II, Jammu & Kashmir</p>
                                <p className='m-0'><img src="./images/icons/pin.svg" width='20px' alt="" /> Chakan, Pune, Maharashtra</p>
                                <p className='m-0'><img src="./images/icons/pin.svg" width='20px' alt="" /> Kundli, Haryana</p>
                                <p className='m-0'><img src="./images/icons/pin.svg" width='20px' alt="" /> Hosur, Tamil Nadu</p>
                                <p className='m-0'><img src="./images/icons/pin.svg" width='20px' alt="" /> Rudrapur, Uttarakhand</p>
                                <p className='m-0'><img src="./images/icons/pin.svg" width='20px' alt="" /> Haridwar, Uttarakhand</p>
                            </div>
                        </div>
                        <div className="country_dot dot2">
                            <div className="content_country">
                                <h6 className='m-0'><img className='me-2' src="./images/icons/mexico.svg" width='35px' alt="" />Mexico</h6>
                            </div>
                        </div>
                        <div className="country_dot dot3">
                            <div className="content_country">
                                <h6>
                                <img className='me-2' src="./images/icons/italy.svg" width='35px' alt="" />Italy
                                </h6>
                                <p className='m-0'><img src="./images/icons/pin.svg" width='20px' alt="" /> Plant Pero</p>
                                <p className='m-0'><img src="./images/icons/pin.svg" width='20px' alt="" /> Progind</p>
                            </div>
                        </div>
                        <div className="country_dot dot4">
                            <div className="content_country">
                                <h6>
                                <img className='me-2' src="./images/icons/romania.svg" width='35px' alt="" />Romania</h6>
                                <p className='m-0'><img src="./images/icons/pin.svg" width='20px' alt="" /> Bacau</p>
                                <p className='m-0'><img src="./images/icons/pin.svg" width='20px' alt="" /> Bacau - II</p>
                            </div>
                        </div>
                    </div> */}
              </div>
            </section>

            <section>
              <div className="container">
                <div className="row">
                  {indiadata.map((item) => (
                    <div className="col-md-3 col-6 mb-4" key={item.id}>
                      <div className="card p-3 box_shadow">
                        <div className="custom_img">
                          <img src={item.imgurl} alt="" />
                        </div>
                        <h6
                          className="text-center mt-2"
                          style={{ fontSize: "15px" }}
                        >
                          {item.prodName}
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>

          <div
            className={`tab-pane ${location.search === "?international" ? "show active" : ""
              }`}
            id="International"
            role="tabpanel"
            aria-labelledby="International-tab"
          >
            <InternationalManu />
          </div>
        </div>
      </section>
    </>
  );
};

export const Manufacturing = () => {
  return (
    <>
      <Helmet>
        <title>Our Manufacturing Facility | Viney Corporation Limited</title>
        <meta
          name="description"
          content="Our Modern manufacturing capabilities ensure the production of high-quality, reliable connecting & mechatronic systems. Contact us for more information."
        />
      </Helmet>
      <Jumbohead
        headertitle="Manufacturing"
        Pagename=" Manufacturing"
        bgurl='url("./images/page-header2.webp")'
      />

      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-10">
              <img
                src="./images/worldmap.png"
                className="img-fluid"
                alt="Manufacturing"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="light-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-3">
              <Mainheader heading="Manufacturing Facilities in India" />
              <p>
                We have established four manufacturing facilities in India
                (located at Kathua, UT of J&K; Manesar, Haryana; Kundli, Haryana
                and Pune, Maharashtra), two manufacturing facilities in Italy
                (located at Milan and Turin) and one manufacturing facility and
                one internal production unit, both located in Romania (located
                at Bacău). We also have three assembly units in India (located
                at Kathua, UT of J&K; Hosur, Tamil Nadu and Rudrapur,
                Uttarakhand).Please see below details of certifications obtained
                by our manufacturing facilities and assembly units in India.{" "}
              </p>
            </div>
            <div
              className="col-md-6 order-1 order-md-2 mb-3 mb-md-0"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-once="true"
            >
              {/* <div className="custom_img">
                      <img src="./images/rd5.webp" alt="Viney Corpoartion" />
                    </div> */}
            </div>
            <div className="col-md-4 mb-3">
              <div className="bg-white p-3 rounded h-100">
                <h4 className="text-blue">Manesar, Haryana</h4>
                {/*<p>*/}
                {/*  <b>*/}
                {/*    Products Manufactured:-{" "}*/}
                {/*    <span className="text-blue">Terminals, Connectors</span>{" "}*/}
                {/*  </b>*/}
                {/*</p>*/}
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="bg-white p-3 rounded h-100">
                <h4 className="text-blue">Kundli, Haryana</h4>
                {/*<p>*/}
                {/*  <b>*/}
                {/*    Products Manufactured:-{" "}*/}
                {/*    <span className="text-blue">Terminals, Connectors</span>{" "}*/}
                {/*  </b>*/}
                {/*</p>*/}
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="bg-white p-3 rounded h-100">
                <h4 className="text-blue">Kathua-1, Jammu</h4>
                {/*<p>*/}
                {/*  <b>*/}
                {/*    Products Manufactured:-{" "}*/}
                {/*    <span className="text-blue">Terminals, Connectors</span>{" "}*/}
                {/*  </b>*/}
                {/*</p>*/}
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="bg-white p-3 rounded h-100">
                <h4 className="text-blue">Kathua-2, Jammu</h4>
                {/*<p>*/}
                {/*  <b>*/}
                {/*    Products:-{" "}*/}
                {/*    <span className="text-blue">Terminals, Connectors</span>{" "}*/}
                {/*  </b>*/}
                {/*</p>*/}
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="bg-white p-3 rounded h-100">
                <h4 className="text-blue">Pune, Maharashtra</h4>
                {/*<p>*/}
                {/*  <b>*/}
                {/*    Products:-{" "}*/}
                {/*    <span className="text-blue">*/}
                {/*      Terminals{" "}*/}
                {/*    </span>{" "}*/}
                {/*  </b>*/}
                {/*</p>*/}
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="bg-white p-3 rounded h-100">
                <h4 className="text-blue">Hosur, Tamil Nadu</h4>
                {/*<p>*/}
                {/*  <b>*/}
                {/*    Products:- <span className="text-blue">Wiring Harness</span>{" "}*/}
                {/*  </b>*/}
                {/*</p>*/}
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="bg-white p-3 rounded h-100">
                <h4 className="text-blue">Rudarpur, Uttarakhand</h4>
                {/*<p>*/}
                {/*  <b>*/}
                {/*    Products:- <span className="text-blue">Wiring Harness</span>{" "}*/}
                {/*  </b>*/}
                {/*</p>*/}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-3">
              <Mainheader
                heading="International"
                subHeading="About Vimercati"
              />
              <p>
                Our manufacturing facilities in Milan and Turin, Italy and
                Bacău, Romania cater to other European countries and USA. We are
                also in the process of setting up a Mechatronic Products
                manufacturing facility in Mexico to explore business
                opportunities in North and South America. We conduct in-house
                R&D at our manufacturing facility at Milan, Italy.
              </p>
            </div>
          </div>
          <div className="row mt-3 mt-md-5">
            <div
              className="col-md-6 mb-3 mb-md-5"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-once="true"
            >
              <h3 className="text-blue">Viney Corp. East Europe SRL</h3>
              <ul className="ps-4 achiev_list">
                <li className="position-relative">
                  Viney Corp. East Europe SRL was incorporated on January 20,
                  2015 as limited liability company limited under the laws of
                  Romania. The company registration number of Viney Corp.
                </li>
                <li className="position-relative">
                  East Europe S.R.L. is J4/68/2015. The registered office and
                  principal place of business of Viney Corp. East Europe S.R.L.
                  is located at 05 Calea Romanului Street, Bacău, Bacău county,
                  Romania. Viney Corp. East Europe S.R.L. is currently engaged
                  in the business of manufacture of other plastic products (NACE
                  Code 2229).
                </li>
              </ul>
            </div>
            <div
              className="col-md-6 mb-3 mb-md-5"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-once="true"
            >
              <h3 className="text-blue">Plant Milan (Vimercati Spa) </h3>
              <ul className="ps-4 achiev_list">
                <li className="position-relative">
                  Vimercati S.p.A was incorporated on November 21, 2007 as a
                  private company limited under the laws of Italy. The company
                  registration number of Vimercati S.p.A. is MI-1861504
                </li>
                <li className="position-relative">
                  The registered office of Vimercati S.p.A. is located at Via
                  Vincenzo Monti, No. 38, Pero (MI). Vimercati S.p.A is in the
                  business of engaging in mechanical, electromechanical, and
                  electronic activities, as well as manufacturing industrial
                  products, both independently and on behalf of third parties.
                </li>
              </ul>
            </div>
            <div
              className="col-md-6 mb-3 mb-md-5"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-once="true"
            >
              <h3 className="text-blue">Plant Turin (Progind Srl)</h3>
              <ul className="ps-4 achiev_list">
                {/* <li className='position-relative'>Area: 9,000 sqm</li>
                    <li className='position-relative'>Capital intensive production </li>
                    <li className='position-relative'>Manpower: 85</li> */}
                <li className="position-relative">
                  Progind SRL was incorporated on June 26, 1997 under the laws
                  of Italy. The Company's registration number is TO886835.
                </li>
                <li className="position-relative">
                  The registered office of Progind SRL is located at Strada
                  Tomboleto, no. 1. Progind SRL is in the business of design and
                  production of molding plastic parts and other metal parts.
                </li>
              </ul>
            </div>
            <div
              className="col-md-6 mb-3 mb-md-5"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-once="true"
            >
              <h3 className="text-blue">
                Plant Bacau (Vimercati East Europe Srl)
              </h3>
              <ul className="ps-4 achiev_list">
                {/* <li className='position-relative'>Area: 10,000 sqm</li>
                        <li className='position-relative'>Labour intensive production </li>
                        <li className='position-relative'>Manpower: 800 </li> */}
                <li className="position-relative">
                  Vimercati East Europe SRL was incorporated on December 12,
                  2007 as a limited liability company under the laws of Romania.
                  The company registration number of Vimercati East Europe
                  S.R.L. is J4/2165/2007.{" "}
                </li>
                <li className="position-relative">
                  The registered office and principle place of business of
                  Vimercati East Europe SRL is located at Hemeiuș, 100 Gării
                  Street, Buildings C1- C15-C17-C18, Bacău county, Romania.
                  Vimercati East Europe SRL is currently engaged in the business
                  of manufacture of other parts and accessories for motor
                  vehicles (NACE Code 2932).{" "}
                </li>
              </ul>
            </div>
            <div
              className="col-md-6 mb-3 mb-md-5"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-once="true"
            >
              <h3 className="text-blue">
                Vimercati Viney Do México S. de R.L. de C.V
              </h3>
              <ul className="ps-4 achiev_list">
                {/* <li className='position-relative'>Area: 9,000 sqm</li>
                    <li className='position-relative'>Capital intensive production </li>
                    <li className='position-relative'>Manpower: 85</li> */}
                <li className="position-relative">
                  Vimercati Viney de Mexico was incorporated on May 18, 2023 as
                  Vimercati Viney Do Mexico Sociedad De Responsabilidad Limitada
                  De Capital Variable under the laws of Republic of Mexico,
                  Gobierno de Nuevo Leon.
                </li>
                <li className="position-relative">
                  The registered office and principle place of business of
                  Vimercati Viney Do Mexico S De Rl De Cv is located at
                  Monterrey, Nuevo Leon, Mexico. It is currently engaged in the
                  business of automotive mechatronics components.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
