import React from "react";
import { Jumbohead } from "../Common/Jumbohead";
import { Helmet } from "react-helmet";

export const BoardofDirectors = () => {
  return (
    <>
      <Helmet>
        <title>Our Board of Directors | Viney Corporation Limited</title>
        <meta
          name="description"
          content="Our esteemed Board of Directors is dedicated to shaping the future of connectivity & mechatronics with excellence at Viney Corporation. Contact us today."
        />
      </Helmet>
      <Jumbohead
        headertitle="Board of Directors"
        Pagename=" Board  of Directors"
        bgurl='url("./images/page-header2.webp")'
      />

      {/* <div className="heading text-center mt-5">
                  <h3>Independent Director of our Company</h3>
            </div> */}

      <section>
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-8 order-2 order-md-1"
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-once="true"
            >
              <h3 className="text-blue">Mr. Brijesh Aggarwal</h3>
              <h5>Managing Director and Promoter</h5>
              {/* <p className='d-none' style={{ textAlign: 'justify' }}>Mr. Brijesh Aggarwal is in the business for around 3 decades and is 2nd generation in the business.
                       Managing Director of the company and responsible for managing Indian and European operations, Strategy, and large client relationship management in India.Instrumental in European business acquisition and growth.
                       Instrumental in European business acquisition and growth.
                          Hands on with the business and has shown business acumen while managing and growing Viney Group.
                              Brought many soft changes in recent years and the appointment of one of the Big 4s as auditor in India and in Italy was one of the many steps in this direction.</p> */}
              <p style={{ textAlign: "justify" }}>
                Mr. Brijesh Aggarwal is a Managing Director of our Company. He
                holds a bachelor's degree in commerce from University of Delhi.
                He has been associated with the Company since April 1, 2004. He
                has 20 years of experience in the automotive manufacturing
                industry. He is responsible for managing the operations of our
                Company in Europe and India, taking strategical decisions for
                expansion of our Company and for managing the client
                relationships in India. He is also associated with VL - Auto
                Ancillary Private Limited and Viney Auto Ancillary Private
                Limited.
              </p>
            </div>
            <div
              className="col-md-4 mb-3 mb-lg-0 order-1 order-md-2"
              data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-once="true"
            >
              <div className="custom_img">
                <img
                  src="./images/employees/kmp/Brijesh_Aggarwal.webp"
                  alt="Brijesh Aggarwal"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="light-bg">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-4 mb-3 mb-lg-0"
              data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-once="true"
            >
              <div className="custom_img">
                <img
                  src="./images/employees/directors/Dinesh_Chopra.webp"
                  alt="Dinesh Chopra"
                />
              </div>
            </div>
            <div
              className="col-md-8"
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-once="true"
            >
              <h3 className="text-blue">Mr. Dinesh Chopra</h3>
              <h5>Independent Director</h5>
              <p style={{ textAlign: "justify" }}>
                Dinesh Chopra is an Independent Director of our Company. He
                holds a degree in bachelors of science from University of Delhi,
                a bachelors of Science Technology (Textile Chemistry) from
                University Department of Chemical Technology, University of
                Bombay, a diploma in Marketing and Sales Management from
                Bhartiya Vidya Bhavan-Delhi Kendra and a post graduate diploma
                in business management from Y.M.C.A, institute of Management
                Studies. He has 37 years of experience in marketing and sales
                management. He has been associated with the Company since 2024.
                He has previously worked at BASF Asia Pacific (India) Private
                Limited, Neochem Technologies Private Limited, Engelhard Asia
                Pacific Enterprises (India) Private Limited, Honeywell
                International (India) Private Limited, ICI India Limited,
                Mafatlal Dyes and Chemicals Limited, The Hindoostan Spinning and
                Weaving Mills Limited and Vam Organic Chemicals Limited. He is
                also a partner at M/s. D R Enterprises since June 9, 2017.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-8 order-2 order-md-1"
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-once="true"
            >
              <h3 className="text-blue">Ms. Manisha Lahoti </h3>
              <h5>Independent Director</h5>
              <p style={{ textAlign: "justify" }}>
                Manisha Lahoti is an Independent Director of our Company. She
                holds a bachelors degree in Commerce from Maharashi Dayanand
                Saraswati University, a post graduate diploma in business
                administration from Symbiosis Centre for Distance Learning. She
                is a member of the Institute of Chartered Accountant of India
                since 2012. She has 11 years of experience as a professional
                chartered accountant. She has been associated with the Company
                since July 12, 2024. She is currently a partner at B.R.
                Maheshwari & Co, LLP/ She has been a partner at B.R. Maheshwari
                & Co, LLP since 2015.
              </p>
            </div>
            <div
              className="col-md-4 mb-3 mb-lg-0 order-1 order-md-2"
              data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-once="true"
            >
              <div className="custom_img">
                <img
                  src="./images/employees/directors/Manisha_Lahoti.webp"
                  alt="Manisha Lahoti"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="light-bg">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-4 mb-3 mb-lg-0"
              data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-once="true"
            >
              <div className="custom_img">
                <img
                  src="./images/employees/directors/Neeraj_Bansal.webp"
                  alt="Neeraj Bansal"
                />
              </div>
            </div>
            <div
              className="col-md-8"
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-once="true"
            >
              <h3 className="text-blue">Mr. Neeraj Bansal</h3>
              <h5>Independent Director</h5>
              <p style={{ textAlign: "justify" }}>
                Neeraj Bansal is an Independent Director of our Company. He
                holds a bachelor's degree in arts from Kurukshetra University,
                Kurukshetra and a master's degree in business administration
                from Copenhagen Business School. He also holds a certificate for
                completion of course from Duke Corporate Education in
                association with Google Business Academy. He has been associated
                245 with the Company since 2024. He has 11 years of experience
                in business development and sales management. He has previously
                worked at Adknowledge Asia Pacific (India) Private Limited, DHL
                eCommerce (India) LLP, Ericsson Communications Private Limited,
                Google Ireland Limited, IgniteWorld Private Limited (formerly
                called BSB Portal Limited), Reliance Jio Infocomm Limited,
                Siemens Limited, Sony Ericsson Mobile Communications Management
                Limited and Wingcopter GmbH. He is also a founder of Marsastro
                Tech Intelligence Private Limited.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-8 order-2 order-md-1"
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-once="true"
            >
              <h3 className="text-blue">Mr. Akash Garg </h3>
              <h5>Non-Executive Director</h5>
              <p style={{ textAlign: "justify" }}>
                Akash Garg is a Non-Executive Director of our Company. He holds
                a bachelors degree in commerce from Kurukshetra University,
                Kurukshetra. He has been associated with the Company since March
                22, 2024. He has been involved for 11 years in various
                businesses owned by his family in manufacturing of oil.
              </p>
            </div>
            <div
              className="col-md-4 mb-3 mb-lg-0 order-1 order-md-2"
              data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-once="true"
            >
              <div className="custom_img">
                <img src="./images/team.webp" alt="Akash Garg" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
