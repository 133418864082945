import React from 'react'
import { Link } from 'react-router-dom'

export const Jumbohead = ({headertitle, Pagename,bgurl}) => {
  return (
    <>
        <div
            className="page-header  theme-undefined"
            // style={{
            //     backgroundImage:
            //     bgurl?bgurl:'url("./images/page-header.webp")',
            //     backgroundRepeat: "no-repeat",
            //     backgroundSize: "cover",
            //     backgroundPosition: "center center",
            //     backgroundAttachment: "inherit"
            // }}
            >
            <div className="page-header-overlay" />
            <div className="page-header-wrapper">
                <div className="container">
                    <div className="d-flex justify-content-center row">
                        <div className="col-md-12">
                        <div className="page-header-inner">
                            <div className="page-header-title">
                            <h2 className="header-title text-white">{headertitle}</h2>
                            </div>
                            <ul className="page-breadcrumbs">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item text-blue">{Pagename}</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
