import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Disclaimer from '../Pages/DRHP/Disclaimer'


export const Sidebar = ({openaside , setOpenAside}) => {
    const handleClose =()=>{
        setOpenAside(false)
    } 
    const [modalOpen , setModalOpen] = useState(false);
  return (
    <>
        <aside className={openaside ? 'show' : ''}>
        <i className="fa-solid fa-xmark" role='button' onClick={()=> setOpenAside(false)}></i>
            <ul className='list-unstyled'>
                <li className='d-lg-none'>
                  <NavLink to='/' onClick={handleClose}><h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Home</h6></NavLink>
                </li>
                <li className='d-lg-none'>
                  <NavLink to='/aboutus' onClick={handleClose}><h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>About us</h6></NavLink>
                    <HashLink smooth to="/aboutus/#history" onClick={handleClose}>History</HashLink>
                    {/* <HashLink smooth to="/our-beliefs" onClick={handleClose}>Our Beliefs</HashLink> */}
                    <HashLink smooth to="/aboutus/#company-profile" onClick={handleClose}>Company Profile</HashLink>
                      <Link to='/board-of-directors' onClick={handleClose}>Board of Directors</Link>
                    <Link to='/key-managerial-personnel'  onClick={handleClose}>Key Managerial Personnel</Link>
                      <Link to='/senior-management' onClick={handleClose}>Senior Management</Link>
                    <Link to='/key-employees-of-Subsidiaries' onClick={handleClose}>Key Employees of Subsidiaries</Link>
                      <Link to='/awards-accreditations' onClick={handleClose}>Awards and Accreditations</Link>
                </li>
              <li className='d-lg-none'>
                  <NavLink to='/products' onClick={handleClose}><h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Products</h6></NavLink>
                  <Link onClick={handleClose} to='/connective-products'>Connective Products</Link>
                  <Link onClick={handleClose} to='/mechatronics-products'>Mechatronic Products</Link>
              </li>
              <li className='d-lg-none'>
                  <h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Business Operations</h6>
                  <Link onClick={handleClose} to='/manufacturing'>Manufacturing Facilities</Link>
                  <Link onClick={handleClose} to='/research-and-development'>Research and Development</Link>
                  <Link onClick={handleClose} to='/worldwide-presence'>World Wide Presence</Link>
              </li> 
               {/* <li className='d-lg-none'>
                 <h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Sustainability</h6>
                 <Link to='/esg-profile' onClick={handleClose}>ESG Profile</Link>
                 <Link to='/brsr' onClick={handleClose}>BRSR</Link>
                 <Link to='/corporate-social-responsibilty' onClick={handleClose}>CSR</Link>
                 </li>  */}
                 <li className='d-lg-none'>
                    <NavLink to='/clients' onClick={handleClose}><h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Key Clients</h6></NavLink>
                 </li>
               <li className='d-lg-none'>
                  <h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Investors</h6>
                  <Link to='/investor-relations-platform' onClick={handleClose}>Investor Relations Platform</Link>
                  <Link to='/disclosure-under-regulation-46-of-SEBI-LODR' onClick={handleClose}>Disclosure Under Regulation <br /> 46 of SEBI LODR</Link>
                  <h6 className='fw-bold pb-1 mb-0 text-blue text-start' style={{fontSize:'16px'}}> - Key Documents</h6>
                  <Link style={{whiteSpace:'wrap'}} 
                  onClick={async () => {
                    await setModalOpen(!modalOpen);
                    handleClose();
                  }}
                  >Draft Red Herring Prospectus (DRHP)</Link>
                  <Link target='_blank' to='/key-documents/IndustryReport(Sep 2024).pdf' >Industry Report</Link>
              </li> 
              {/* <li>
                  <h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Careers </h6>
                  <Link to='/careers' onClick={handleClose}>Overview</Link>
                  <Link to='/careers/#openings' onClick={handleClose}>Life and Openings</Link>
                  <Link to='/faqs' onClick={handleClose}>FAQs</Link>
                  </li> */}
              {/* <li>
                  <h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Media</h6>
                  <Link to='/comingsoon' onClick={handleClose}>Press Release and News</Link>
                  <Link to='/blogs' onClick={handleClose}>Blogs and Press Kit</Link>
              </li> */}
              <li>
                  {/* <h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Privacy and Legal</h6> */}
                   {/* <Link to='/term-condition' onClick={handleClose}><h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Terms and Conditions</h6></Link>
                 <Link to='/privacy-policy' onClick={handleClose}> <h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Privacy Policy and Disclaimer</h6></Link> */}
              </li>
              <li>
                  <NavLink to='/contact-us' onClick={handleClose}><h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Contact Us</h6></NavLink>
              </li>
              <li>
                  <NavLink to='/careers' onClick={handleClose}><h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Careers</h6></NavLink>
              </li>
              {/* <li>
                  <NavLink to='/corporate-social-responsibilty' onClick={handleClose}><h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>CSR</h6></NavLink>
              </li>
              <li>
                      <NavLink to='/sustainability' onClick={handleClose}><h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Sustainability</h6></NavLink>
                      <Link to='/esg-profile' onClick={handleClose}>ESG Profile</Link>

              </li> */}
              {/*<li>*/}
              {/*        <NavLink to='/disclosures' onClick={handleClose}><h6 className='fw-bold pt-3 pb-1 mb-0 text-blue'>Disclosures</h6></NavLink>*/}
              {/*</li>*/}
            </ul>
        </aside>
        <div className={`side_ovrlay ${openaside ? 'd-block' : 'd-none'}`} onClick={()=> setOpenAside(false)}></div>

        <Disclaimer modalOpen={modalOpen} onClose={() => setModalOpen(false)}/>
    </>
  )
}
