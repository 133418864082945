import React from 'react';
import HeroSlider from '../Sliders/Sliders';
import { ClientsTab } from './Clients';
import { InvestorsHome } from './Investors';
import { News } from './News';
import { Product } from './Product';
import { Sustainbility } from './Sustainbility';
import { Link } from 'react-router-dom';
import { Acquisition } from './Acquisition';
import Mainheader from '../Common/Mainheader';
import { AwardsandCertifications } from '../Aboutus/AwardsandCertifications';
import { Helmet } from 'react-helmet';

export const Home = () => {


    return (
        <>
            <Helmet>
                <title>Viney Corporation Limited</title>
                <meta name='description' content='Viney Corporation is one the leading companies in providing connective and mechatronic systems products across the globe. Contact us for innovative solutions.' />
            </Helmet>
            <HeroSlider />

            <section id="about2" className="about">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <Mainheader heading='Overview' subHeading='One of the leading manufacturer, supplier and exporter of automotive components (Source: CRISIL Report)' />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-12 col-md-12 col-lg-7 order-2 order-lg-1" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                            <div className="about__text mt-30">
                                <p>We design, manufacture, supply and export a wide array of automotive component products for two-wheeler vehicles, threewheeler vehicles, passenger vehicles and commercial vehicles. Our product portfolio includes steering wheel switches, gear
                                    shifting paddles, light control units, brake pedal switches and sensors, multimedia plugs and airbag on/off switches (either
                                    visible switches or hidden switches) (“Mechatronic Products”); and wiring harnesses, fuse boxes, wire, cables, terminals, and
                                    connectors (“Connective Products”). We are a Tier-1 supplier for original equipment manufacturers (“OEMs”) for both
                                    Mechatronic Products and Connective Products, and a Tier-2 supplier and Tier-3 supplier for OEMs for Connective Products.
                                    As of March 31, 2024, one out of every six two-wheelers sold in India are fitted with one of our device wiring harness (Source:
                                    CRISIL Report)</p>
                                <Link to="/aboutus" className='theme-btn mt-3'>Read More</Link>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-5 order-1 order-lg-2 mb-4 mb-md-0" data-aos="fade-left" data-aos-duration="1500" data-aos-once='true'>
                            <div className="custom_img">
                                <img src="./images/about2.webp" alt="about" className="img-fluid" />
                            </div>
                        </div>
                    </div>

                    {/* <div className="feactured_crd">
            <div className="col-sm-12 col-md-12 mt-3 mt-md-0">
              <div className="row features-list features-list-layout1">
                <div className="col-md-4" data-aos="fade-up" data-aos-duration="1000" data-aos-once='true'>
                  <div className="feature-item feature-list-item">
                    <Link to="our-beliefs">
                      <div className="feature__content">
                        <img src="./images/icons/beliefs.svg" alt="" />
                        <h4 className="feature__title">Heading to be replaced</h4>
                        <p className="feature__desc">We believe the company's strong foundation and strategic focus on growth position it well for future success. With a diverse product portfolio and emphasis on operational excellence, we see continued potential for market expansion. </p>
                        <Link className="readmorebtn readmore mt-3" to="/our-beliefs">Read More <i className="fa-solid fa-arrow-right-long moverdown"></i></Link>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-4" data-aos="fade-up" data-aos-duration="1500" data-aos-once='true'>
                  <div className="feature-item feature-list-item">
                    <Link to="/manufacturing">
                      <div className="feature__content">
                        <img src="./images/icons/manufacturer.svg" alt="" />
                        <h4 className="feature__title">Manufacturing Facilities</h4>
                        <p className="feature__desc">We have established four manufacturing facilities in India (located at Kathua, UT of J&K; Manesar, Haryana; Kundli, Haryana and Pune, Maharashtra), two manufacturing facilities in Italy (located at Milan and Turin) and one manufacturing facility and one internal production unit, both located in Romania (located at Bacău).</p>
                        <Link className="readmorebtn readmore mt-3" to="/manufacturing">Read More <i className="fa-solid fa-arrow-right-long moverdown"></i></Link>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000" data-aos-once='true'>
                  <div className="feature-item feature-list-item">
                    <Link to="/worldwide-presence">
                      <div className="feature__content">
                        <img src="./images/icons/worldwide.svg" alt="" />
                        <h4 className="feature__title">Heading to be replaced</h4>
                        <p className="feature__desc"></p>
                        <a className="readmorebtn readmore mt-3" href="">Read More <i className="fa-solid fa-arrow-right-long moverdown"></i></a>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


                </div>
            </section>

            <Product />

            <InvestorsHome />

            <ClientsTab />
            <Acquisition />
            <Sustainbility />

            <AwardsandCertifications />

            {/* <News /> */}
        </>
    );
}
