import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import Mainheader from '../Common/Mainheader'
import { Helmet } from 'react-helmet'

export const ResearchandDevelopment = () => {
  return (
    <>
        <Helmet>
            <title>Our Research & Development | Viney Corporation</title>
            <meta name='description' content='Our research & development efforts focused on innovation & excellence in connection & mechatronic systems. Our team are committed to create high-tech solutions.' />
        </Helmet>
         <Jumbohead headertitle="Research and Development" Pagename=" Research and Development" bgurl='url("./images/page-header2.webp")'/>

         <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12 order-2 order-md-1" data-aos="fade-right" data-aos-duration="1500" data-aos-once='true'>

                    <Mainheader heading='Research and Development'  />
                  <p className='text-justify'>Our R&D teams primarily engage in new product development, design prototyping, tool development, and product upgrades.
                  Our dedication to R&D remains a driving force for our Company. </p>
                  <p className='text-justify'>We conduct in-house R&D at our manufacturing facilities at Milan, Italy and at our R&D centre located at Gurugram, Haryana,
                      India. Our R&D teams primarily engage in new product development, design prototyping, tool development, and product
                      upgrades. The R&D centre at our manufacturing facility at Milan, Italy focuses on R&D in connection with Mechatronics
                      Products, whereas the R&D centre at Gurugram, Haryana, India focuses R&D in connection with Connective Products. The
                      R&D team in Gurugram, Haryana, India is headed by Mr. Neeraj Bhargav and the R&D team at Milan, Italy is headed by Mr.
                      Marco Branco (Electrical R&D) and Mr. Claudio Cerasa (Mechanical R&D). As of March 31, 2024, we have a dedicated team
                      of 28 individuals engaged in R&D at our R&D centre at Gurugram, Haryana, India and a R&D team comprising 25 individuals
                      at our manufacturing facility at Milan, Italy. </p>
                  <p className='text-justify'>Our focus on R&D has resulted in innovative product development. For instance, in Mechatronics Products, our latest
innovations include the creation of sockets designed specifically for EV charging. Similarly, in Connective Products, we have
developed the main wiring harness for both, two-wheeler vehicles and three-wheeler vehicles, addressing the specific needs for
flexi-vehicle platform of a large Indian automotive company.</p>
              </div>
              {/* <div className="col-md-6 order-1 order-md-2 mb-3 mb-md-0" data-aos="fade-left" data-aos-duration="1500" data-aos-once='true'>
                <div className="custom_img">
                  <img src="./images/R&D.webp" alt="Viney Corpoartion" />
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section style={{backgroundColor:'#f4f4f4'}} className='d-none'>
                <div className="container">
                      <div className="heading text-center mb-4" data-aos="fade-down" data-aos-duration="1500" data-aos-once='true'>
                          <h3>
                            Our Dedication
                          </h3>
                          <p>Our dedication to R&D remains a driving force, enabling us to stay at the forefront of technological advancements and contribute meaningfully to diverse industries. We will continue to invest in our in-house R&D, design, and engineering capabilities, including R&D talent across our research centers.</p>
                      </div>
                      <div className="row mt-3 mt-md-5">
                        <div className="col-md-3 col-6">
                            <div className="card rounded box_shadow overflow-hidden">
                                <div className="custom_img rounded-0">
                                    <img src='./images/rd1.webp' className='rounded-0' alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="card rounded box_shadow overflow-hidden">
                                <div className="custom_img rounded-0">
                                    <img src='./images/rd2.webp' className='rounded-0' alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="card rounded box_shadow overflow-hidden">
                                <div className="custom_img rounded-0">
                                    <img src='./images/rd3.webp' className='rounded-0' alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="card rounded box_shadow overflow-hidden">
                                <div className="custom_img rounded-0">
                                    <img src='./images/rd4.webp' className='rounded-0' alt="" />
                                </div>
                            </div>
                        </div>
                      </div>
                </div>
        </section>
    </>
  )
}
