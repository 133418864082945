import React from 'react'
import { Link } from 'react-router-dom'

const Thankyou = () => {
  return (
    <>
         <div
            className="page-header theme-undefined"
            style={{
                backgroundImage:'url("./images/page-header.webp")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundAttachment: "inherit",
                height:'100px'
            }}
            >
            <div className="page-header-overlay" />
            <div className="page-header-wrapper">
                <div className="container">
                </div>
            </div>
        </div>

        <section>
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-md-6">
                        <img src="./images/thankyou.svg" alt="" />
                    </div>
                    <div className="col-md-10">
                        <h4>Thank you for contacting with us</h4>
                        <p>We will contact you as soon as possible. We are committed to providing you with an unforgettable experience.</p>
                        <Link to="/" className='theme-btn'>Back to Home</Link>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Thankyou