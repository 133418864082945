import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export const Comingsoon = () => {
  return (
    <>
            <Helmet>
                <title>Comingsoon | Viney Corporation</title>
                <meta name='description' content='Viney Corporation is one the leading companies in providing connective and mechatronic systems products across the globe. Contact us for innovative solutions.' />
            </Helmet>
        <section className='comingsoon' style={{backgroundImage:'url(./images/comingsoon.jpg)',backgroundPosition:'center',backgroundSize:'cover',height:'100vh'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h1>Coming Soon</h1>
                        <p className='text-white'>We are working hard to bring you something amazing. Stay tuned!</p>
                        <Link to="/" className='theme-btn mt-3'>Back to Home</Link>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
