import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import CountUp from 'react-countup'
import { Link } from 'react-router-dom'

export const Investors = () => {

    const highlights = [
        {
            id:'1',
            icon:'fa-solid fa-chart-simple',
            counter:'43001',
            heading:'Total Revenue'
        },
        {
            id:'2',
            icon:'fa-solid fa-chart-line',
            counter:'2786',
            heading:'EBITDA'
        },
        {
            id:'3',
            icon:'fa-solid fa-percent',
            counter:'2985',
            heading:'Profit Before Tax'
        },
        {
            id:'4',
            icon:'fa-solid fa-coins',
            counter:'2845',
            heading:'Profit After Tax'
        },
        {
            id:'5',
            icon:'fa-solid fa-money-bill-wave',
            counter:'1386',
            heading:'Free Cash Flow'
        },
        {
            id:'6',
            icon:'fa-solid fa-chart-pie',
            counter:'20',
            heading:'ROCE (%)'
        }
    ]

  return (
    <>
     <Jumbohead headertitle="Investors" Pagename=" Investors" bgurl='url("./images/investors_bg.webp")'/>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-7" data-aos="fade-right" data-aos-duration="1500" data-aos-once='true'>
                  <div className="heading">
                        <h4 className="heading_span fw-medium">
                           Overview
                        </h4>
                  </div>
                  <p>Viney Corporation is dedicated to ensuring that stakeholders remain informed about our progress. Guided by our commitment to trust, transparency, and accountability, Viney Corporation maintains an ongoing and open dialogue with its shareholders.</p>
              </div>
              <div className="col-md-5" data-aos="fade-left" data-aos-duration="1500" data-aos-once='true'>
                <div className="custom_img">
                    <img src="./images/investor_overview.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{backgroundColor:'#f4f4f4'}}>
           <div className="container">
                <div className="heading text-center">
                    <h4 className="heading_span fw-medium">
                        Enhancing shareholder value
                    </h4>
                    <h3>Investors Dashboard</h3>
                </div>
                <div className="row mt-3 mt-md-5 investor">
                    <div className="col-lg-4 col-sm-6 my-3 my-lg-0" data-aos="fade-up" data-aos-duration="2000" data-aos-once='true'>
                        <div className="investors_crd">
                            {/* <img src="./images/icons/quartly.svg" alt="" /> */}
                            <img src="./images/investors.webp" className='img-fluid w-100' alt="" />
                           <div className="ps-3 pb-3">
                             <span className=' d-block mt-3'>Annual Reports</span>
                            <a href="">Read More <i className="fa-solid fa-arrow-right-long"></i></a>
                           </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 my-3 my-lg-0" data-aos="fade-up" data-aos-duration="2500" data-aos-once='true'>
                        <div className="investors_crd">
                            {/* <img src="./images/icons/Anual.svg" alt="" /> */}
                            <img src="./images/investors1.webp" className='img-fluid w-100' alt="" />
                           <div className="ps-3 pb-3">
                             <span className=' d-block mt-3'>Quarterly Results</span>
                            <a href="">Read More <i className="fa-solid fa-arrow-right-long"></i></a>
                           </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 my-3 my-lg-0" data-aos="fade-up" data-aos-duration="3000" data-aos-once='true'>
                        <div className="investors_crd">
                            {/* <img src="./images/icons/invester.svg" alt="" /> */}
                            <img src="./images/investors2.webp" className='img-fluid w-100' alt="" />
                           <div className="ps-3 pb-3">
                             <span className=' d-block mt-3'>Investor Presentations</span>
                            <a href="">Read More <i className="fa-solid fa-arrow-right-long"></i></a>
                           </div>
                        </div>
                    </div>
                </div>
           </div>
        </section>

        {/* <section className='pt-0'>
            <div className="container">
                <div className="row">
                    <div className="col-12 mt-3 mt-md-5">
                        <div className="highlights">
                            <div className="heading text-center">
                                <h3>FY2023 Performance Highlights</h3>
                                <h4 className="heading_span fw-medium">
                                    (Consolidated)(Rs. in million)
                                </h4>
                            </div>
                            <div className="row mt-3 mt-md-4">
                               {highlights.map((item)=>
                                    <div className="col-md-4 mb-3 mb-md-4" key={item.id} data-aos="fade-up" data-aos-duration="1000" data-aos-once='true'>
                                        <div className="card">
                                            <div className="content_hl">
                                                <div className="icon">
                                                    <i class={item.icon}></i>
                                                </div>
                                                <h2><CountUp enableScrollSpy={true} end={item.counter}/></h2>
                                                <h6 className='fw-normal'>{item.heading}</h6>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

        <section style={{backgroundColor:'#f4f4f4'}}>
            <div className="container">
                <div className="heading text-center">
                    <h3>Investor Updates</h3>
                </div>
                <div className="row mt-3 mt-md-4">
                    <div className="col-md-6 mb-3">
                        <a className="update_link">
                            <div className="ul_icon">
                                <img src="./images/icons/saving.svg" alt="" />
                            </div>
                            Financial statements
                        </a>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Link className="update_link" to='/disclosure-under-regulation-46-of-SEBI-LODR'>
                            <div className="ul_icon">
                                <img src="./images/icons/box.svg" alt="" />
                            </div>
                            Disclosure Under Regulation 46 of SEBI LODR
                        </Link>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Link to='/corporate-social-responsibilty' className="update_link">
                            <div className="ul_icon">
                                <img src="./images/icons/social-responsibility.svg" alt="" />
                            </div>
                            Corporate Social Responsibilty
                        </Link>
                    </div>
                    <div className="col-md-6 mb-3">
                        <a className="update_link">
                            <div className="ul_icon">
                                <img src="./images/icons/news.svg" alt="" />
                            </div>
                            Press Release
                        </a>
                    </div>
                    {/* <div className="col-md-6 mb-3">
                        <Link className="update_link" to='disclosures'>
                            <div className="ul_icon">
                                <img src="./images/icons/box.svg" alt="" />
                            </div>
                            Disclosures and Filings
                        </Link>
                    </div> */}
                </div>
            </div>
        </section>


    </>
  )
}
