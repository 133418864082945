import React, { useState, useEffect } from 'react';
import $ from 'jquery'; // Import jQuery if necessary

export const Backtop = () => {
  const [isActiveProgress, setIsActiveProgress] = useState(false);

  useEffect(() => {
    // Event listeners
    $(window).on('scroll', () => {
      if ($(window).scrollTop() > 50) {
        setIsActiveProgress(true);
      } else {
        setIsActiveProgress(false);
      }
    });

  }, []);

  const scrollToTop = () => {
    $('html, body').animate({ scrollTop: 0 }, 550);
  };

  return (
    <>
        <div onClick={scrollToTop} className={`progress-wrap ${isActiveProgress ? 'active-progress' : ''}`}>
          <i class='bx bx-chevrons-up bx-flashing' ></i>
        </div>
    </>
  )
}
