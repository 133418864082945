import React from 'react'
import CountUp from 'react-countup'
import Mainheader from '../Common/Mainheader'
import { Link } from 'react-router-dom'

export const InvestorsHome = () => {
  return (
    <>
        <section className="investor">
            <div className="container">
                <div className="row">
                    <div className="col-12 mt-3 mt-md-0 mb-5" data-aos="fade-right" data-aos-duration="1000" data-aos-once='true'>
                        <div className="heading text-center">
                              <h2 className="heading__title">Investor Relations</h2>
                            {/*<p className=''>Fostering sustainable long-term growth with consistent industry outperformance and robust corporate governance.</p>*/}
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 my-3 my-lg-0" data-aos="fade-up" data-aos-duration="2000" data-aos-once='true'>
                        <div className="investors_crd shadow">
                             {/* <img src="./images/icons/quartly.svg" alt="" />  */}
                            {/* <img src="./images/investors.webp" className='img-fluid w-100' alt="" /> */}
                           <div className="ps-3 py-3">
                             <span className=' d-block'>Financial Statements</span>
                            <Link target='_blank' to="https://esg.churchgatepartners.com/Login/CompanyProfile?id=3100340039003600240024004100530048004F004B0041004E0041004E00590041004100560041004E004900410053004800570049004E00490024002400&KID=Financial%20Results">Read More <i className="fa-solid fa-arrow-right-long"></i></Link>
                           </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 my-3 my-lg-0" data-aos="fade-up" data-aos-duration="2500" data-aos-once='true'>
                        <div className="investors_crd shadow">
                             {/* <img src="./images/icons/Anual.svg" alt="" />  */}
                            {/* <img src="./images/investors1.webp" className='img-fluid w-100' alt="" /> */}
                           <div className="ps-3 py-3">
                             <span className=' d-block'>Industry Report</span>
                            <Link target='_blank' to="/key-documents/IndustryReport(Sep%202024).pdf">Read More <i className="fa-solid fa-arrow-right-long"></i></Link>
                           </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 my-3 my-lg-0" data-aos="fade-up" data-aos-duration="3000" data-aos-once='true'>
                        <div className="investors_crd shadow">
                             {/* <img src="./images/icons/invester.svg" alt="" />  */}
                            {/* <img src="./images/investors2.webp" className='img-fluid w-100' alt="" /> */}
                           <div className="ps-3 py-3">
                             <span className=' d-block'>Other Information</span>
                            <Link to="/investor-relations-platform">Read More <i className="fa-solid fa-arrow-right-long"></i></Link>
                           </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-12 mt-3 mt-md-4">
                    <div className='counters row'>
                    <div className='col-lg-3 col-4'>
                        <div className="text-center count_crd">
                        <img src="./images/icons/factory.svg" alt="" />
                        <div className="counts">
                            <CountUp enableScrollSpy={true} end={11}/>
                        </div>
                        <div className="count_head">
                            Manufacturing Units
                        </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-4'>
                        <div className="text-center count_crd">
                        <img src="./images/icons/workforce.svg" alt="" />
                        <div className="counts">
                            <CountUp enableScrollSpy={true} end={3}/>
                        k+
                        </div>
                        <div className="count_head">
                        Workforce
                        </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-4'>
                        <div className="text-center count_crd">
                        <img src="./images/icons/segments.svg" alt="" />
                        <div className="counts">
                        
                            All
                        </div>
                        <div className="count_head">
                            Vehicle Segments
                        </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-4'>
                        <div className="text-center count_crd">
                        <img src="./images/icons/employes.svg" alt="" />
                        <div className="counts">
                        <CountUp enableScrollSpy={true} end={800}/>+
                        </div>
                        <div className="count_head">
                            Employees
                        </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-4'>
                        <div className="text-center count_crd">
                        <img src="./images/icons/stock.svg" alt="" />
                        <div className="counts">
                        US $<CountUp enableScrollSpy={true} end={140}/>
                        </div>
                        <div className="count_head">
                        Million Group
                        </div>
                        </div>
                    </div>
                    </div>
                </div> */}
            </div>
        </section>
    </>
  )
}
