import React from 'react'
import { CertificationsSlide } from '../Sliders/Sliders'
import { Link } from 'react-router-dom'

export const AwardsandCertifications = () => {
  return (
    <>
        <section className='overflow-hidden' style={{backgroundColor:'#f4f4f4'}}>
            <div className="certifications">
                <div className="row">
                    <div className="col-md-4 mt-5" data-aos="fade-right" data-aos-duration="1500" data-aos-once='true'>
                        <div className="heading">
                            <h3 className='text-blue'>Awards and Accreditations</h3>
                            <p>We have been recognised by our customers and industry organisations for our excellence in business.</p>
                              <Link to="/awards-accreditations" className='theme-btn'>View All Awards</Link>
                        </div>
                        </div>
                    <div className="col-md-8 pe-0" data-aos="fade-left" data-aos-duration="1500" data-aos-once='true'>
                        <CertificationsSlide/>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
