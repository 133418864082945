import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { Autoplay, EffectFade, FreeMode, Navigation, Pagination } from 'swiper/modules';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';


export const Clients = () =>{
    return(
      <Swiper pagination={true} navigation={true} modules={[Navigation]} className="mySwiper">
      <SwiperSlide>
          <div className="briefs_wrapper p-4 br-16 mt-3 mt-md-5">
          <div className="row">
              <div className="col-md-4">
                  <img src="./images/Beliefs.webp" className='w-100 rounded-3' alt="" />
              </div>
              <div className="col-md-8">
              <h4>Our Beliefs</h4>
              <p>"We serve our customers with innovation and technical expertise; it is this shared culture of teamwork and utmost integrity that distinguishes Viney"</p>
              <a href="#" className="btn-blue mt-auto mt-3">
                  <span>READ MORE</span>
              </a>
              </div>
          </div>
          </div>  
      </SwiperSlide>
      <SwiperSlide>
          <div className="briefs_wrapper p-4 br-16 mt-3 mt-md-5">
          <div className="row">
              <div className="col-md-4">
                  <img src="./images/manufacturing.webp" className='w-100 rounded-3' alt="" />
              </div>
              <div className="col-md-8">
              <h4>Manufacturing Facility</h4>
              <p>In India, the Company has unique core competence in the manufacture of Wiring Harness, Connectors and Terminals. Key products include Wiring Harness, Connectors, Fuse Boxes, Terminals, Bulbs, Cap Noise Suppressors, Cap Filters, Relays and Switches.</p>
              <a href="#" className="btn-blue mt-auto mt-3">
                  <span>READ MORE</span>
              </a>
              </div>
          </div>
          </div>  
      </SwiperSlide>
      <SwiperSlide>
          <div className="briefs_wrapper p-4 br-16 mt-3 mt-md-5">
          <div className="row">
              <div className="col-md-4">
                  <img src="./images/worlwide.webp" className='w-100 rounded-3' alt="" />
              </div>
              <div className="col-md-8">
              <h4>Worldwide Presence</h4>
              <p>In November 2011, with a view to give a thrust to growth, Viney Corporation Private Limited acquired majority holding (100%) in Italy-based mechatronics firm Vimercati Spa. The trusted supplier to OEMs as well as global auto giants such as....</p>
              <a href="#" className="btn-blue mt-auto mt-3">
                  <span>READ MORE</span>
              </a>
              </div>
          </div>
          </div>  
      </SwiperSlide>
  </Swiper>
    )
}

export default function HeroSlider() {
  return (
    <>
      <Swiper
        effect={'fade'}
        navigation={true}
        modules={[EffectFade, Navigation, Pagination,Autoplay]}
        loop={true}
        autoplay={{
            delay:4000,
        }}
        className="heroSwiper"
      >
        <SwiperSlide>
          <div className="slide_outer position-relative">
            <div className="hero_slide" style={{backgroundImage:'url(./images/banner.jpg)'}}>
            </div>
                <div className="container">
                  <div className="slide_content">
                    <h1>One of the Leading Manufacturer</h1>
                              <h3>Supplier and Exporter of Automotive Components with a Diverse Range of Products (Source: CRISIL Report)</h3>
                    <Link to="/aboutus" className='theme-btn mt-3'>Read More</Link>
                  </div>
                </div>
          </div>
        </SwiperSlide>
        {/*<SwiperSlide>*/}
        {/*  <div className="slide_outer position-relative">*/}
        {/*    <div className="hero_slide" style={{backgroundImage:'url(./images/banner.jpg)'}}>*/}
        {/*    </div>*/}
        {/*        <div className="container">*/}
        {/*          <div className="slide_content">*/}
        {/*            <h1>Pioneering Breakthrough In</h1>*/}
        {/*            <h3>Mechanical, Mechatronics & Electronic Technologies</h3>*/}
        {/*            <Link to="/aboutus" className='theme-btn mt-3'>Read More</Link>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*  </div>*/}
        {/*</SwiperSlide>*/}
       
      </Swiper>
    </>
  );
}


export const CertificationsSlide=()=>{
  return(
    <div className="certificates_img">
      <Slider 
        slidesToShow={3}
        arrows={false}
        autoplay={true}
        slidesToScroll={1}
        initialSlide={0}
        responsive={ [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },     
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          }        
        ]}
    >
        <div className="custom_img">
          <a href="">
            <img src="./images/awards/MindaStoneridgeInstrumentsLimited.jpg" alt="" />
            <div className="content_certificate">
              Internal Kaizen Competition
            </div>
          </a>
        </div>  
        <div className="custom_img">
          <a href="">
            <img src="./images/awards/MindaStoneridgeInstrumentsLimited1.jpg" alt="" />
             <div className="content_certificate">
              Best Support During Development
            </div>
          </a>
        </div>  
        <div className="custom_img">
          <a href="">
            <img src="./images/awards/SparkMinda.jpg" alt="" />
             <div className="content_certificate">
              Certificate of Appreciation
            </div>
          </a>
        </div>  
        <div className="custom_img">
          <a href="">
            <img src="./images/awards/SparkMinda1.jpg" alt="" />
             <div className="content_certificate">
             3rd Annual QC & Kaizen Competition
            </div>
          </a>
        </div>  
    </Slider>
    </div>
  )
}