import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'

export const Faqs = () => {

    
  return (
    <>
        <Jumbohead headertitle="Faqs " Pagename="faqs" bgurl='url("./images/page-header5.jpg")' />

        <section style={{ backgroundColor: '#f4f4f4' }}>
                <div className="container">
                    <div className="heading text-center">
                        <h3 className='text-blue'>Frequently Ask Questions</h3>
                        <h4>We've got you covered</h4>
                    </div>

                    <div className="accordion main-accordian mt-5" id="accordionExample">
                        <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <h6 className='m-0'>What is the Automotive components manufacturing industry?</h6>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" >
                                <div className="accordion-body">
                                   <p>To be useful and functioning, any vehicle requires a significant number of parts. With over 30,000 parts in a single vehicle, automakers rely on large suppliers to help them get these parts to market and build assembly lines.</p>
                                   <p>Automotive component manufacturers make up the majority of the car parts components manufacturing business. In the whole automobile industry, stock production is the largest segment.</p>
                                   <p>Auto component manufacturing is part of a larger supply chain (see diagram below) that includes raw material extraction, component manufacturing for vehicle manufacture (see previous section), distribution, vehicle sales (see separate section), aftermarket and added-value sales, and vehicle disposal and recycling at the end of their useful lives.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    <h6 className='m-0'>What courier service do you use?</h6>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" >
                                <div className="accordion-body">
                                   <p>As we have mentioned that all transportation charges are to bore by customer. We use highly reliable & cheapest mode of transport possible to deliver our products so that it becomes affordable for our customers.</p>
                                   <p>Approx. charges are RS:-63 per kg for small packages (maruti courier services) & heavy cargos will be delivered through transport</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    </>
  )
}
