import React, { useState } from 'react'
import { Clients } from '../Sliders/Sliders'

export const ClientsTab = () => {
    const [addVehicle , setAddVehicle] = useState(1);
    const tier1Customer = [
        //{id:'1',imgURL:'./images/clients/new/Denso.png'},
        {id:'2',imgURL:'./images/clients/new/napino.png'},
        {id:'3',imgURL:'./images/clients/new/UNO-MINDA.png'},
        {id:'4',imgURL:'./images/clients/new/varoc.png'},
        {id:'5',imgURL:'./images/clients/new/jns.png'},
        {id:'6',imgURL:'./images/clients/new/lumax.svg'},
        //{id:'7',imgURL:'./images/clients/new/PhiniaLogo.webp'},
        {id:'8',imgURL:'./images/clients/new/sparkminda.png'},
        {id:'9',imgURL:'./images/clients/new/zenmo.webp'},
        // {id:'10',imgURL:'./images/clients/new/BMW.png'},
        // {id:'11',imgURL:'./images/clients/new/stellantis.png'},
    ]
  return (
    <>
        <section className="clients keyclients">
        <div className="container">
                    <div className="heading text-center mb-4" data-aos="fade-down" data-aos-duration="1500" data-aos-once='true'>
                        <h2 className='heading__title'>Key Clients</h2>
                    </div>
                    <div className="row">
                        {tier1Customer.map((item)=>
                            <div className="col-md-3 col-6" key={item.id}>
                                <div className="card p-3 position-relative rounded-0 bg-transparent border">
                                    <div className="custom_img rounded-0 ">
                                        <img src={item.imgURL} className='rounded-0' alt="" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
               {/* <div className="row" data-aos="fade-up" data-aos-duration="2000" data-aos-once='true'>
                <div className="col-12 mb-5">
                    <ul className="nav nav-tabs justify-content-between" id="myTab2" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="twowheeler2-tab" data-bs-toggle="tab" data-bs-target="#twowheeler2" type="button" role="tab" aria-controls="twowheeler2" aria-selected="true" onClick={(prev)=> setAddVehicle(1)}> 2 Wheeler</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="threewheeler2-tab" data-bs-toggle="tab" data-bs-target="#threewheeler2" type="button" role="tab" aria-controls="threewheeler2" aria-selected="false" onClick={(prev)=> setAddVehicle(2)} >3 Wheeler</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="fourwheeler2-tab" data-bs-toggle="tab" data-bs-target="#fourwheeler2" type="button" role="tab" aria-controls="fourwheeler2" aria-selected="false" onClick={(prev)=> setAddVehicle(3)} >4 Wheeler</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="passenger2-tab" data-bs-toggle="tab" data-bs-target="#passenger2" type="button" role="tab" aria-controls="passenger2" aria-selected="false" onClick={(prev)=> setAddVehicle(4)} >Passenger vehicles (PV)</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="commercial2-tab" data-bs-toggle="tab" data-bs-target="#commercial2" type="button" role="tab" aria-controls="commercial2" aria-selected="false" onClick={(prev)=> setAddVehicle(5)} >Commercial vehicles (CV)</button>
                        </li>
                    </ul>
                </div>
                <div className="col-md-4">
                    <div className="heading">
                        <h4 className="heading_span fw-medium">
                            Our Clients
                        </h4>
                        <h3>We Built Best Solutions For Your Company</h3>
                    </div>
                    <div className='d-none d-lg-block'>
                        {addVehicle === 1 ? <img src="./images/bike.png" className='img-fluid animate_left' alt="" /> : ""}
                            {addVehicle === 2 ? <img src="./images/tuktuk.png" className='img-fluid animate_left' alt="" /> : ""  }
                            {addVehicle === 3 ? <img src="./images/car.png" className='img-fluid animate_left' alt="" /> : ""  }
                            {addVehicle === 4 ? <img src="./images/passengervehicle.png" className='img-fluid animate_left' alt="" /> : ""  }
                            {addVehicle === 5 ? <img src="./images/cv.png" className='img-fluid animate_left' alt="" /> : ""  }
                        
                    </div>
                </div>
                <div className="col-md-8" >
                    <div className="tab-content" id="myTabContent" >
                        <div className="tab-pane fade show active" id="twowheeler2" role="tabpanel" aria-labelledby="twowheeler2-tab">
                            <div className="card-group">
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/yamaha.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3">
                                    <img className="card-img-top" src="./images/clients/new/bajaj.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3">
                                    <img className="card-img-top" src="./images/clients/new/ampere.jpeg" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/tvs.png" alt="Card image cap"  /> 
                                </div>
                            </div>
                            <div className="card-group">
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/logo-20.jpeg" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3">
                                    <img className="card-img-top" src="./images/clients/new/revolt.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3">
                                    <img className="card-img-top" src="./images/clients/logo-15.jpeg" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/hero.png" alt="Card image cap"  /> 
                                </div>
                                
                            </div>
                            <div className="card-group">
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/polaris.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3">
                                    <img className="card-img-top" src="./images/clients/new/honda.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3">
                                    <img className="card-img-top" src="./images/clients/new/ultraviolette.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/heroelectric.png" alt="Card image cap"  /> 
                                </div>
                            </div>

                        </div>
                          
                        <div className="tab-pane fade" id="threewheeler2" role="tabpanel" aria-labelledby="threewheeler2-tab">
                            <div className="card-group">
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/bajaj.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 ">
                                    <img className="card-img-top" src="./images/clients/new/mahindra.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/logo1.jpeg" alt="Card image cap"  /> 
                                </div>
                            </div>
                            <div className="card-group">
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/Piaggio-logo.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/atul.jpg" alt="Card image cap"  /> 
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="fourwheeler2" role="tabpanel" aria-labelledby="fourwheeler2-tab">
                            <div className="card-group">
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/hundai.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 ">
                                    <img className="card-img-top" src="./images/clients/new/mahindra.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/mahindra.jpg" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/renault.png" alt="Card image cap"  /> 
                                </div>
                            </div>
                            <div className="card-group">
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/maruti-suzuki.svg" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/tata.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/VinFast.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/bmw.png" alt="Card image cap"  /> 
                                </div>
                            </div>
                            <div className="card-group">
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/fiat.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/ferrari.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/chrysler.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/Volkswagen.svg" alt="Card image cap"  /> 
                                </div>
                            </div>
                           
                        </div>
                        <div className="tab-pane fade" id="passenger2" role="tabpanel" aria-labelledby="passenger2-tab">
                            <div className="card-group">
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/hundai.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 ">
                                    <img className="card-img-top" src="./images/clients/new/mahindra.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/mahindra.jpg" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/renault.png" alt="Card image cap"  /> 
                                </div>
                            </div>
                            <div className="card-group">
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/maruti-suzuki.svg" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/tata.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/ashok.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/nisaan.png" alt="Card image cap"  /> 
                                </div>
                            </div>
                       
                        </div>
                        <div className="tab-pane fade" id="commercial2" role="tabpanel" aria-labelledby="commercial2-tab">
                             <div className="card-group">
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/ashok.png" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 ">
                                    <img className="card-img-top" src="./images/clients/new/isuzu.png" alt="Card image cap"  /> 
                                </div>
                            </div>
                            <div className="card-group">
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/switch.jpeg" alt="Card image cap"  /> 
                                </div>
                                <div className="card p-3 rounded-0">
                                    <img className="card-img-top" src="./images/clients/new/VE-Commercial.jpg" alt="Card image cap"  /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               </div> */}
            </div>
        </section>
    </>
  )
}
