import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import { Helmet } from 'react-helmet'

const KMPSMP = () => {
    const managementIndia=[
        {
            name:'Mr. Dinesh Chand Sharma ',
            desi:'Chief Financial Officer',
            imgSRC:'./images/employees/kmp/DC_sharma.webp',
            pera:'Dinesh Chand Sharma is the Chief Financial Officer of our Company. He is a member of the Institute of Chartered Accountant of India since 1991. He has been associated with the Company since March 15, 2023. He has 33 years of experience as achartered accountant. He has previously worked at Eastman Auto and Power Limited, Minda anagement Services Limited,Okaya Power Private Limited.'
        },
        {
            name:'Mr. Sunil Gupta',
            desi:'Company Secretary and Compliance Officer',
            imgSRC:'./images/employees/kmp/Sunil_Gupta.webp',
            pera:'Sunil Gupta is the Company Secretary and Compliance Officer of our Company. He holds a degree in Bachelor of Commerce from University of Rajasthan. He has been associated with the Company since June 3, 2015. He has 10 years of experience ascompany secretary. He has been a member of the Institute of Company Secretaries of India since January 10, 2014. He has previously worked at DLA Infrastructures Private Limited as a company secretary.'
        }
    ]
  return (
      <>
          <Helmet>
              <title>Key Managerial Personnel | Viney Corporation Limited</title>
              <meta name='description' content='Our Key Managerial Personnel who drive our strategic vision & operational excellence. Guiding the company toward sustained growth & uphold the highest standards' />
          </Helmet>
          <Jumbohead headertitle="Key Managerial Personnel" Pagename="Key Managerial Personnel" bgurl='url("./images/page-header2.webp")' />
          <section className="pt-5">
              <div className="container">
                  <div className="row mt-5 smp">
                      {managementIndia.map((item, index) => (
                          <div className="col-md-6 mb-3" key={index}>
                              <div className="news_crd read_crd mb-2" data-aos="fade-up" data-aos-duration="2000" data-aos-once='true'>
                                  <div className="custom_img">
                                      <img src={item.imgSRC} className='rounded-3 w-100' alt="" />
                                  </div>
                                  <h4 className='mt-3 mb-0 fw-bold text-blue'>{item.name}</h4>
                                  <figcaption className='mb-3 text-dark-50'><b>{item.desi}</b></figcaption>
                                  <p style={{ textAlign: 'justify' }}>{item.pera}</p>
                              </div>
                          </div>
                      ))}
                  </div>
              </div>
          </section>
    </>
  )
}

export default KMPSMP