import React from 'react'
import { Jumbohead } from '../Common/Jumbohead'
import { Helmet } from 'react-helmet'

export const AwardsCertifications = () => {
    const awards = [
        {
            imgURL:'./images/awards/MindaStoneridgeInstrumentsLimited.jpg',
            heading:'Internal Kaizen Competition',
            pera:'Secured second place in the Kaizen Internal Competition held by Minda Stoneridge Instruments Limited.'
        },
        {
            imgURL:'./images/awards/MindaStoneridgeInstrumentsLimited1.jpg',
            heading:'Best Support During Development',
            pera:'Awarded certificate for Best Support During Development in sixth annual supplier meet - Matheran held by Minda Stoneridge Instruments Limited.'
        },
        {
            imgURL:'./images/awards/SparkMinda.jpg',
            heading:'Certificate of Appreciation',
            pera:'Awarded certificate of Appreciation from Spark Minda for exemplary performance and persistent efforts to achieve outstanding results'
        },
        {
            imgURL:'./images/awards/SparkMinda1.jpg',
            heading:'3rd Annual QC & Kaizen Competition',
            pera:' Awarded certificate of special appreciation for “Best case study presentation” at the 3rd Annual QC and Kaizen Competition hosted by Spark Minda.'
        },
        {
            imgURL:'./images/awards/Spark-Minda2.jpg',
            heading:'Cost & Quality',
            pera:'Received an award for being a part of the supply chain transformation: cost and quality from Spark Minda.'
        },
        {
            imgURL:'./images/awards/Spark-Minda3.jpg',
            heading:'Certificate of Appreciation',
            pera:'Awarded certificate of Appreciation from Spark Minda recognition for effective implementation of MACE system .'
        },
        {
            imgURL:'./images/awards/Spark-Minda-Award.jpg',
            heading:'Special Appreciation Award',
            pera:'Received a special appreciation award for best case study presentation from Spark Minda .'
        },
        {
            imgURL:'./images/awards/Spark-Minda-Award1.jpg',
            heading:'Supplier Conference',
            pera:'Received an award for exemplary Performance at the Supplier conference held in June, 2023 from Spark Minda.'
        },
        {
            imgURL:'./images/awards/Spark-Minda-Award2.jpg',
            heading:'Quality Month Celebration',
            pera: 'Received an award from Spark Minda at the Quality month Celebration for “Tier 2 MACE VSA - Audit Green Category.'
        },
    ]
  return (
    <>
            <Helmet>
                <title>Awards and Accreditations | Viney Corporation</title>
                <meta name='description' content='Our Awards and Accreditations highlight our commitment to excellence and industry standards. Trust us for certified and award-winning solutions today.' />
            </Helmet>
         <Jumbohead headertitle="Awards and Accreditations" Pagename=" Awards and Accreditations" bgurl='url("./images/page-header7.webp")' />

         <section>
            <div className="container">
                <div className="row">
                    {awards.map((item,index)=>(
                        <div className="col-md-4 mb-4" key={index}>
                            <div className="awrd_card p-2">
                                <span></span><span></span><span></span><span></span>
                                <div className="custom_img">
                                    <img src={item.imgURL} alt={item.heading} />
                                </div>
                            </div>
                            <div className="mt-2">
                            <h6>{item.heading}</h6>
                            <p>{item.pera}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
         </section>
    </>
  )
}
