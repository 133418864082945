import React from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

const Disclaimer = ({modalOpen , onClose}) => {
  return (
    <>
      <div
        class={`modal fade disclaimer ${modalOpen ? "show" : ""}`}
        id="modalId"
        tabindex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        style={{ display: modalOpen ? "block" : "none" }}
        role="dialog"
        aria-labelledby="modalTitleId"
      >
        <div
          class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title fs-3" id="modalTitleId">
                Disclaimer - Important
              </h5>
              <button
                type="button"
                class="btn-close"
                onClick={onClose}
              ></button>
            </div>
            <div class="modal-body">
              <h6>
                THESE MATERIALS ARE NOT DIRECTED AT OR INTENDED TO BE ACCESSED
                BY PERSONS LOCATED OUTSIDE INDIA. THESE MATERIALS ARE BEING MADE
                AVAILABLE ON THIS WEBSITE TO COMPLY WITH SECURITIES AND EXCHANGE
                BOARD OF INDIA (ISSUE OF CAPITAL AND DISCLOSURE REQUIREMENTS)
                REGULATIONS, 2018, AS AMENDED.
              </h6>
              <p>
                <b className="text-danger">IMPORTANT:</b> You must read and
                agree with the terms and conditions of the following disclaimer
                before continuing.
              </p>

              <p>
                The following disclaimer applies to the Draft Red Herring
                Prospectus of <b>Viney Corporation Limited</b> (the “
                <b>Company</b>”) dated September 30, 2024 (the “
                <b>Draft Red Herring Prospectus</b>”) filed with the Securities
                and Exchange Board of India (“<b>SEBI</b>”), BSE Limited (“
                <b>BSE</b>”) and National Stock Exchange of India Limited (“
                <b>NSE</b>”, and together with BSE, the “<b>Stock Exchanges</b>
                ”) and hosted on this website. In accessing the Draft Red
                Herring Prospectus, you agree to be bound by the following terms
                and conditions, including any modifications to them from time to
                time.{" "}
              </p>
              <p>
                The Draft Red Herring Prospectus is directed at, and is intended
                for distribution to, and use by, residents of India only. The
                information in this portion of our website, including the Draft
                Red Herring Prospectus, is not for publication or distribution,
                directly or indirectly, in or into the United States. No part of
                the contents of the Draft Red Herring Prospectus shall be copied
                or duplicated in any form by any means or redistributed.
              </p>
              <p>
                The Draft Red Herring Prospectus has been hosted on this website
                as prescribed under Regulation 26(1) of the SEBI (Issue of
                Capital and Disclosure Requirements) Regulations, 2018, as
                amended (the “<b>SEBI ICDR Regulations</b>”). You are reminded
                that documents transmitted in electronic form may be altered or
                changed during the process of transmission and consequently,
                neither the Company nor any of its affiliates accepts any
                liability or responsibility whatsoever in respect of alterations
                or changes which have taken place during the course of
                transmission of electronic data.
              </p>
              <p>
                The Draft Red Herring Prospectus does not constitute an offer to
                sell or an invitation to subscribe to the securities offered in
                any jurisdiction to any person to whom it is unlawful to make an
                offer or invitation in such jurisdiction and is not intended for
                distribution to, or use by, any person or entity in any
                jurisdiction or country where (a) distribution or use of such
                information would be contrary to law or regulation; or (b) the
                Company or any of its affiliates would by virtue of such
                distribution become subject to new or additional registration,
                licensing or other regulatory requirements. The Equity Shares
                offered in the Offer have not been and will not be registered
                under the U.S. Securities Act of 1933, as amended (the “
                <b>U.S. Securities Act</b>”) or any state securities laws in the
                United States. Accordingly, the Equity Shares are being offered
                and sold outside of the United States in offshore transactions
                and in compliance with Regulation S under the U.S. Securities
                Act and the applicable laws of the jurisdiction where such
                offers and sales are made.
              </p>
              <p>
                Any person into whose possession the Draft Red Herring
                Prospectus comes is required to inform himself or herself about
                and to observe any such restrictions. Neither the Company nor
                any of its affiliates is soliciting any action based on the
                Draft Red Herring Prospectus, and it should not be construed as
                an offer to sell or the solicitation of an offer to buy any
                securities. Potential investors should not rely on the Draft Red
                Herring Prospectus for any investment decision. Any potential
                investor should note that investment in the securities involves
                a high degree of risk. For details, potential investors should
                refer to the red herring prospectus, including the section
                titled “Risk Factors”, when available.{" "}
              </p>
              <h6 className="text-uppercase text-underline">
                If you are not permitted to view the materials on this website
                or are in any doubt as to whether you are permitted to view
                these materials, please exit this webpage.
              </h6>
              <p>
                To access this information, you must confirm by pressing on the
                button marked “I Confirm” that, at the time of access you are
                located in India. If you cannot make this confirmation, you must
                press the button marked “ <b>I Do Not Confirm</b>”.{" "}
              </p>
              <p>
                The documentation contained in these pages is posted solely to
                comply with Indian legal and regulatory requirements. Making the
                information contained herein available in electronic format does
                not constitute an offer to sell, the solicitation of an offer to
                buy, or a recommendation to buy or sell securities of the
                Company in the United States or in any other jurisdiction,
                including without limitation, India.
              </p>
            </div>
            <div class="modal-footer justify-content-center">
              <a href='./key-documents/240930_Switch-Consolidated DRHP(Filing).pdf' target='_blank'  className="theme-btn">
                {" "}
                I Confirm
              </a>
              <button
                type="button"
                class="btn-dark theme-btn border-0"
                style={{ backgroundColor: "#302e2e" }}
                onClick={async () => {
                  await toast.error(
                    "You are not permitted to view the materials in this section of the website.",
                    {
                      position: "top-center", // Adjust this as needed
                    }
                  );
                  onClose();
                }}
              >
                I Do Not Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`disclaimer_backdrop ${modalOpen ? 'd-block' : 'd-none'}`}></div>
    </>
  );
}

export default Disclaimer