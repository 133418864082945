import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Mainheader from '../Common/Mainheader';

export const Product = () => {
    const [pdtbackground, setPdtbackground] = useState(1);
    const twowheeler = [
        { id: 1, imgurl: './images/2W/BLINKERWH.jpg', prodName: 'BLINKER WH' },
        { id: 2, imgurl: './images/2W/FRONTSTOPWH.jpg', prodName: 'FRONT STOP WH+' },
        { id: 1, prodName: 'Device Wiring Harness', imgurl: './images/products/wiring_s.png' },
        // { id: 4, imgurl: './images/2W/Lockwh.jpg', prodName: 'LOCK WH' },
        { id: 4, prodName: 'Moulded parts', imgurl: './images/products/moulded_s.png' },
        { id: 5, imgurl: './images/2W/terminal.png', prodName: 'Terminal' },
        { id: 6, imgurl: './images/2W/REARSTOPWH.jpg', prodName: 'REARSTOP WH' },
        { id: 7, imgurl: './images/2W/SELFSTARTWH.jpg', prodName: 'SELFSTART WH' },
        { id: 8, imgurl: './images/2W/connectors.png', prodName: 'Connectors' },
        { id: 9, imgurl: './images/2W/SPEEDOMETERWIRE.jpg', prodName: 'SPEEDOMETER WIRE' },
        { id: 10, imgurl: './images/2W/SPEEDSENSORWH.jpg', prodName: 'SPEEDSENSOR WH' },
        { id: 11, imgurl: './images/2W/SWITCHWH.jpg', prodName: 'SWITCH WH' },
        { id: 12, imgurl: './images/2W/TAILLAMPWH.jpg', prodName: 'TAILLAMP WH' },
        // {id:13,imgurl:'./images/2W/TANKUNITWH.jpg',prodName:'TANKUNIT WH'},
        // {id:14,imgurl:'./images/2W/USBCHARGER.jpg',prodName:'USB CHARGER'},
    ]
    const wiring = [
        {
            id: 1, imgurl: './images/products/wiringharness.jpg', prodName: 'Wiring Harness'
        },
        { id: 2, imgurl: './images/products/terminal.png', prodName: 'Terminal' },
        { id: 3, imgurl: './images/products/terminal1.png', prodName: 'Terminal' },
        { id: 4, imgurl: './images/products/connector.png', prodName: 'Connectors' },
        { id: 5, imgurl: './images/products/rubberseal.png', prodName: 'Rubber Seals' },
        { id: 6, imgurl: './images/products/cables.png', prodName: 'Cables ' },
        {
            id: 7, imgurl: './images/2W/BLINKERWH.jpg', prodName: 'Blinker Wiring Harness'
        },
        {
            id: 8, imgurl: './images/2W/FRONTSTOPWH.jpg', prodName: 'Front Stop Switch Wiring Harness'
        },
    ]
    const mechatronic = [
        {
            id: 1, imgurl: './images/products/AirbagDeactivationSwitch.jpg', prodName: 'Airbag Deactivation Switch'
        },
        {
            id: 2, imgurl: './images/products/AutomaticGearSelectorDNASwitch.jpg', prodName: 'DNA Drive Mode / Suspension Level Selector'
        },
        {
            id: 3, imgurl: './images/products/BMWCentralStackConsoleSwitchBar.jpg', prodName: 'Central Stack Console Switch'
        },
        {
            id: 4, imgurl: './images/products/DoorCentralLockingSwitch.jpg', prodName: 'Central Door Locking, Hazard and Traction Control Switch'
        },
        { id: 5, imgurl: './images/products/EngineStartStop Switch.jpg', prodName: 'Engine Start / Stop, Switch' },
        { id: 6, imgurl: './images/ProductPhotos/HeadlightLevellingSwitch.jpg', prodName: 'Headlight Levelling Switch' },
        { id: 7, imgurl: './images/products/HeadlightSwitch.jpg', prodName: 'Light Control Unit' },
        { id: 8, imgurl: './images/products/InteriorDoorLightSensorSwitch.jpg', prodName: 'Door and Trunk Closure Switch  ' },
    ]
    const connectionProduct = [
        { id: 1, name: 'Wiring Harness', imgURL: './images/products/wiring_s.png' },
        { id: 2, name: 'Connectors', imgURL: './images/products/wire_connector.png' },
        { id: 3, name: 'Rubber Seals', imgURL: './images/products/rubberseal_s.png' },
        { id: 4, name: 'Moulded parts', imgURL: './images/products/moulded_s.png' },
        { id: 5, name: 'Terminals', imgURL: './images/products/terminals.png' },
        { id: 6, name: 'Relays', imgURL: './images/products/relay_s.png' },
    ]
    const mechatronicProduct = [
        { id: 1, name: 'Clutch Sensors', imgURL: './images/products/clutchsensor.jpg' },
        { id: 2, name: 'Sliding Door Contacts', imgURL: './images/products/sidingdoor_s.jpg' },
        { id: 3, name: 'LIN Bus Light Unit', imgURL: './images/products/line_bus_light.jpg' },
        { id: 4, name: 'Paddleshifts', imgURL: './images/products/paddleshifts_s.jpg' },
        { id: 5, name: 'Steering wheel switches', imgURL: './images/products/Steering_switch_s.jpg' },
        { id: 7, name: 'Lamp Holders', imgURL: './images/products/lamp_holder_s.jpg' },
        { id: 8, name: 'Visible switches', imgURL: './images/products/Visible_switch-s.jpg' },
        { id: 9, name: 'Airbag off Switches', imgURL: './images/products/AirbagoffSwitches_s.jpg' },
    ]
    const fourWProduct = [
        { id: 1, imgURL: './images/ProductPhotos/item1.jpg', name: 'Engine Start/Stop switch' },
        { id: 1, name: 'Device Wiring Harness', imgURL: './images/products/wiring_s.png' },
        // { id: 4, imgurl: './images/2W/Lockwh.jpg', name: 'LOCK WH' },
        { id: 4, name: 'Moulded parts', imgURL: './images/products/moulded_s.png' },
        { id: 5, imgURL: './images/2W/terminal.png', name: 'Terminal' },
        { id: 2, name: 'Connectors', imgURL: './images/products/wire_connector.png' },
        {
            id: 2, imgURL: './images/ProductPhotos/item2.jpg', name: 'Door switches'
        },
        {
            id: 3, imgURL: './images/ProductPhotos/item3.jpg', name: 'Steering wheel switches'
        },
        { id: 4, imgURL: './images/ProductPhotos/item4.jpg', name: 'Steering wheel switches' },
        {
            id: 5, imgURL: './images/ProductPhotos/item5.jpg', name: 'Modules'
        },
        {
            id: 7, imgURL: './images/ProductPhotos/item6.jpg', name: 'Complex Modules'
        },
        { id: 8, imgURL: './images/ProductPhotos/item7.jpg', name: 'Light Modules' },
        { id: 9, imgURL: './images/ProductPhotos/item8.jpg', name: 'Steering wheel switches' },
    ]

    return (
        <>
            <section className="product">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center" data-aos="fade-up" data-aos-duration="2500" data-aos-once='true'>
                            <Mainheader heading='Products' 
                            // subHeading='Mechatronic Products and Connective Products'
                             />
                            <p>We design, manufacture, supply and export a wide array of automotive component products for two-wheeler vehicles, threewheeler vehicles, passenger vehicles and commercial vehicles. Our product portfolio includes steering wheel switches, gear
                                shifting paddles, light control units, brake pedal switches and sensors, multimedia plugs and airbag on/off switches (either
                                visible switches or hidden switches) (“Mechatronic Products”); and wiring harnesses, fuse boxes, wire, cables, terminals, and
                                connectors (“Connective Products”).</p>
                            {/* <Link to="/products" className='theme-btn mt-3'>Read More</Link> */}
                        </div>
                    </div>
                    <ul className="nav nav-pills justify-content-md-center gap-md-3" id="myTab" role="tablist" data-aos="fade-up" data-aos-duration="2000" data-aos-once='true'>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link px-3 active" id="twowheeler-tab" data-bs-toggle="tab" data-bs-target="#twowheeler" type="button" role="tab" aria-controls="twowheeler" aria-selected="true" onClick={(prev) => setPdtbackground(1)}>
                            Connective Products</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link px-3" id="threewheeler-tab" data-bs-toggle="tab" data-bs-target="#threewheeler" type="button" role="tab" aria-controls="threewheeler" aria-selected="false" onClick={(prev) => setPdtbackground(2)}>
                            Mechatronics Products</button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <button className="nav-link" id="fourwheeler-tab" data-bs-toggle="tab" data-bs-target="#fourwheeler" type="button" role="tab" aria-controls="fourwheeler" aria-selected="false" onClick={(prev) => setPdtbackground(3)}>
                                <div className="tab_icon">
                                    <img src="./images/icons/car.svg" alt="" />
                                </div>
                                4 Wheeler</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="passenger-tab" data-bs-toggle="tab" data-bs-target="#passenger" type="button" role="tab" aria-controls="passenger" aria-selected="false" onClick={(prev) => setPdtbackground(4)}>
                                <div className="tab_icon">
                                    <img src="./images/icons/bus.svg" alt="" />
                                </div>
                                Passenger vehicles (PV)</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="commercial-tab" data-bs-toggle="tab" data-bs-target="#commercial" type="button" role="tab" aria-controls="commercial" aria-selected="false" onClick={(prev) => setPdtbackground(5)}>
                                <div className="tab_icon">
                                    <img src="./images/icons/cv.svg" alt="" />
                                </div>
                                Commercial vehicles (CV)</button>
                        </li> */}
                        {/* <li className="nav-item" role="presentation">
                        <button className="nav-link" id="offroad-tab" data-bs-toggle="tab" data-bs-target="#offroad" type="button" role="tab" aria-controls="offroad" aria-selected="false" onClick={(prev)=> setPdtbackground(6)}>
                            <div className="tab_icon">
                                <img src="./images/icons/offroad.svg" alt="" />
                            </div>
                            OFF Road</button>
                    </li> */}
                    </ul>
                    <div className="tab-content" id="myTabContent" data-aos="fade-right" data-aos-duration="2500" data-aos-once='true'>
                        <div className="tab-pane fade show active" id="twowheeler" role="tabpanel" aria-labelledby="twowheeler-tab">
                            <div className="row mt-4 justify-content-center">
                                {wiring.map((item ,index) =>
                                    <div className="col-6 col-md-3 mb-3" key={index}>
                                        <div className="gallry_card br-16 text-center">
                                            <img className='rounded-3' src={item.imgurl} alt={item.imgurl} />
                                            <p className='mt-2 mb-0 text-center'>{item.prodName}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="text-center">
                                <Link to="/connective-products" className='theme-btn mt-3'>View All Product</Link>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="threewheeler" role="tabpanel" aria-labelledby="threewheeler-tab">
                            <div className="row mt-4 justify-content-center">
                                {mechatronic.map((item ,index) =>
                                    <div className="col-6 col-md-3 mb-3" key={index}>
                                        <div className="gallry_card br-16 text-center">
                                            <img className='rounded-3' src={item.imgurl} alt={item.imgurl} />
                                            <p className='mt-2 mb-0 text-center'>{item.prodName}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="text-center">
                                <Link to="/mechatronics-products" className='theme-btn mt-3'>View All Product</Link>
                            </div>
                        </div>
                        {/* <div className="tab-pane fade" id="fourwheeler" role="tabpanel" aria-labelledby="fourwheeler-tab">
                            <div className="row mt-4 justify-content-center">
                                {fourWProduct.map((item ,index) =>
                                    <div className="col-6 col-md-3 mb-3" key={index}>
                                        <div className="gallry_card br-16 text-center">
                                            <img className='rounded-3' src={item.imgURL} alt={item.imgURL} />
                                            <p className='mt-2 mb-0 text-center'>{item.name}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="text-center">
                                <Link to="/mechatronics-systems" className='theme-btn mt-3'>View All Product</Link>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="passenger" role="tabpanel" aria-labelledby="passenger-tab">
                            <div className="row mt-4 justify-content-center">
                                {fourWProduct.map((item ,index) =>
                                    <div className="col-6 col-md-3 mb-3" key={index}>
                                        <div className="gallry_card br-16 text-center">
                                            <img className='rounded-3' src={item.imgURL} alt={item.imgURL} />
                                            <p className='mt-2 mb-0 text-center'>{item.name}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="text-center">
                                <Link to="/mechatronics-systems" className='theme-btn mt-3'>View All Product</Link>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="commercial" role="tabpanel" aria-labelledby="commercial-tab">
                            <div className="row mt-4 justify-content-center">
                                {fourWProduct.map((item ,index) =>
                                    <div className="col-6 col-md-3 mb-3" key={index}>
                                        <div className="gallry_card br-16 text-center">
                                            <img className='rounded-3' src={item.imgURL} alt={item.imgURL} />
                                            <p className='mt-2 mb-0 text-center'>{item.name}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="text-center">
                                <Link to="/mechatronics-systems" className='theme-btn mt-3'>View All Product</Link>
                            </div>
                        </div> */}
                        {/* <div className="tab-pane fade" id="offroad" role="tabpanel" aria-labelledby="offroad-tab">
                    <div className="row mt-4 justify-content-center">
                            {connectionProduct.map((item ,index)=>
                                <div className="col-6 col-md-3 mb-3" key={index}>
                                    <div className="gallry_card br-16 text-center">
                                        <img className='rounded-3' src={item.imgURL} alt={item.imgURL}/>
                                        <p className='mt-2 mb-0 text-center'>{item.name}</p>
                                    </div>
                                </div>
                            )}
                            </div>
                    </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
